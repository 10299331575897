<div class="row">
    <div class="col-md-12 col-sm-6">
        <div class="card bg-lite mb-3 filter-top">
            <h3 class="card-header filter-heading">
                <span>Phylogeny</span>
            </h3>
            <ul id="ontology-inactive" class="list-group">
                <li *ngIf="(isFilterSelected && filterService.selectedFilterValue!== undefined )"
                    class="rank-heading list-group-item"> {{filterService.selectedFilterValue.rank | uppercase}}
                </li>
                <li *ngIf="(isFilterSelected && filterService.selectedFilterValue!== undefined )"
                    class="list-group-item d-flex justify-content-between align-items-center kingdom active-filter"
                    style="cursor: pointer; padding: .75rem 0.25rem;"
                    id="{{filterService.selectedFilterValue.taxonomy}}-kingdom"
                    (click)="showTaxonomyModal($event, currentTaxonomy.rank, currentTaxonomy.taxonomy, 'subkingdom')">
                    {{filterService.selectedFilterValue.taxonomy}} ({{filterService.selectedFilterValue.commonName}})
                    <span *ngIf="isShowCount === 'true'" class="badge badge-pill badge-info">{{filterService.bioSampleTotalCount}}</span>
                </li>
                <li *ngIf="!isFilterSelected" class="rank-heading list-group-item"> KINGDOM </li>
                <ng-container *ngIf="!this.isFilterSelected">
                    <ng-container *ngFor="let super of this.childTaxanomy.superkingdom">
                        <li *ngFor="let superchild of super.childData"
                            class="list-group-item d-flex justify-content-between align-items-center kingdom"
                            style="cursor: pointer; padding: .75rem 0.25rem;" id="{{superchild.key}}-kingdom"
                            (click)="showTaxonomyModal($event, super.rank, superchild.key, 'subkingdom')"
                            (dblclick)="filterTaxonomy(super.rank, superchild.key, 'subkingdom',superchild.commonName.buckets[0].key, superchild.taxId.buckets[0].key)">
                            {{superchild.key}} ({{superchild.commonName.buckets[0].key}})
                            <span *ngIf="isShowCount === 'true'"  class="badge badge-pill badge-info">{{superchild.doc_count}}</span>
                        </li>
                    </ng-container>
                </ng-container>
            </ul>
        </div>
    </div>
</div>
<div class="modal fade" id="taxonomyModal" tabindex="-1" role="dialog" aria-labelledby="ontologyModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title modal-heading" id="ontologyModal" modal-heading>Taxonomy
                    <i class="fa fa-info-circle" aria-hidden="true" data-toggle="tooltip" data-placement="right" title="1. Each taxonomy is denoted by Scientific name and common name in bracket.&#013;2. Double click to filter by taxonomy."></i>
                    <!-- <span id="tooltip" data-toggle="tooltip" data-placement="top" title="Double click to select rank">&#9432;</span> -->
                </h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideTaxaModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ul id="myUL">
                    <!-- subkingdom level -->
                    <ul class="nested subkingdom" *ngIf="childTaxanomy.kingdom.length > 0">
                        <li class="rank-heading">
                            {{currentTaxaOnExpand.childRank | uppercase}}
                        </li>

                        <ng-container *ngFor="let king of childTaxanomy.kingdom">
                            <ng-container>
                                <li *ngFor="let kingchild of king.childData; let i = index">
                                    <i *ngIf="((king.childData.length == 1 && kingchild.key != 'Other') || (king.childData.length > 1 ))" class="fas fa-plus-circle {{kingchild.key}}-{{king.rank}}" (click)="getChildTaxonomyRankEvent($event, king.rank, kingchild.key, 'superphylum')"></i>
                                    <span *ngIf="((king.childData.length == 1 && kingchild.key != 'Other') || (king.childData.length > 1 ))" class="caret {{kingchild.key}}-{{king.rank}}" id="{{kingchild.key}}-{{king.rank}}" (dblclick)="filterTaxonomy(king.rank, kingchild.key, 'superphylum',kingchild.commonName.buckets[0].key,kingchild.taxId.buckets[0].key)">
                                            {{kingchild.key}} ({{kingchild.commonName.buckets[0].key}})
                                        </span>
                                    <span *ngIf="((king.childData.length == 1 && kingchild.key != 'Other') || (king.childData.length > 1 ))" class="badge badge-pill badge-info {{kingchild.key}}-{{king.rank}}"><span *ngIf="isShowCount === 'true'" >{{kingchild.doc_count}}</span></span>
                                    <hr *ngIf="((king.childData.length == 1 && kingchild.key != 'Other') || (king.childData.length > 1 ))" class="{{kingchild.key}}-{{king.rank}}">
                                    <!-- superphylum level -->
                                    <ul class="nested {{kingchild.key}}-superphylum" *ngIf="childTaxanomy.subkingdom.length > 0">
                                        <ng-container *ngFor="let subkingdom of childTaxanomy.subkingdom">
                                            <ng-container *ngIf="kingchild.key === subkingdom.parent">
                                                <li *ngFor="let subkingchild of subkingdom.childData; let i = index">
                                                    <i *ngIf="((subkingdom.childData.length == 1 && subkingchild.key != 'Other') || (subkingdom.childData.length > 1 ))" class="fas fa-plus-circle {{subkingchild.key}}-{{subkingdom.rank}}" (click)="getChildTaxonomyRankEvent($event, subkingdom.rank, subkingchild.key, 'phylum')"></i>
                                                    <span *ngIf="((subkingdom.childData.length == 1 && subkingchild.key != 'Other') || (subkingdom.childData.length > 1 ))" class="caret {{subkingchild.key}}-{{subkingdom.rank}}" id="{{subkingchild.key}}-{{subkingdom.rank}}" (dblclick)="filterTaxonomy(subkingdom.rank, subkingchild.key, 'phylum',subkingchild.commonName.buckets[0].key,subkingchild.taxId.buckets[0].key)">
                                                            {{subkingchild.key}}
                                                        ({{subkingchild.commonName.buckets[0].key}})
                                                        </span>
                                                    <span *ngIf="((subkingdom.childData.length == 1 && subkingchild.key != 'Other') || (subkingdom.childData.length > 1 ))" class="badge badge-pill badge-info {{subkingchild.key}}-{{subkingdom.rank}}"><span *ngIf="isShowCount === 'true'" >{{subkingchild.doc_count}}</span></span>
                                                    <hr *ngIf="((subkingdom.childData.length == 1 && subkingchild.key != 'Other') || (subkingdom.childData.length > 1 ))" class="{{subkingchild.key}}-{{subkingdom.rank}}">
                                                    <!-- phylum level -->
                                                    <ul class="nested {{subkingchild.key}}-phylum" *ngIf="childTaxanomy.superphylum.length > 0">
                                                        <ng-container *ngFor="let superphylum of childTaxanomy.superphylum">
                                                            <ng-container *ngIf="subkingchild.key === superphylum.parent">
                                                                <li *ngFor="let superphylumchild of superphylum.childData; let i = index">
                                                                    <i *ngIf="((superphylum.childData.length == 1 && superphylumchild.key != 'Other') || (superphylum.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, superphylum.rank, superphylumchild.key, 'subphylum')"></i>
                                                                    <span *ngIf="((superphylum.childData.length == 1 && superphylumchild.key != 'Other') || (superphylum.childData.length > 1 ))" class="caret" id="{{superphylumchild.key}}-{{superphylum.rank}}" (dblclick)="filterTaxonomy(superphylum.rank, superphylumchild.key, 'subphylum',superphylumchild.commonName.buckets[0].key,superphylumchild.taxId.buckets[0].key)">
                                                                            {{superphylumchild.key}}
                                                                        ({{superphylumchild.commonName.buckets[0].key}})
                                                                        </span>
                                                                    <span *ngIf="((superphylum.childData.length == 1 && superphylumchild.key != 'Other') || (superphylum.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'" >{{superphylumchild.doc_count}}</span></span>
                                                                    <hr *ngIf="((superphylum.childData.length == 1 && superphylumchild.key != 'Other') || (superphylum.childData.length > 1 ))">
                                                                    <!-- subphylum level -->
                                                                    <ul class="nested {{superphylumchild.key}}-subphylum" *ngIf="childTaxanomy.phylum.length > 0">
                                                                        <ng-container *ngFor="let phylum of childTaxanomy.phylum">
                                                                            <ng-container *ngIf="superphylumchild.key === phylum.parent">
                                                                                <li *ngFor="let phylChild of phylum.childData; let i = index">
                                                                                    <i *ngIf="((phylum.childData.length == 1 && phylChild.key != 'Other') || (phylum.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, phylum.rank, phylChild.key, 'superclass')"></i>
                                                                                    <span *ngIf="((phylum.childData.length == 1 && phylChild.key != 'Other') || (phylum.childData.length > 1 ))" class="caret" id="{{phylChild.key}}-{{phylum.rank}}" (dblclick)="filterTaxonomy(phylum.rank, phylChild.key, 'superclass',phylChild.commonName.buckets[0].key,phylChild.taxId.buckets[0].key)">
                                                                                            {{phylChild.key}}
                                                                                        ({{phylChild.commonName.buckets[0].key}})
                                                                                        </span>
                                                                                    <span *ngIf="((phylum.childData.length == 1 && phylChild.key != 'Other') || (phylum.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'" >{{phylChild.doc_count}}</span></span>
                                                                                    <hr *ngIf="((phylum.childData.length == 1 && phylChild.key != 'Other') || (phylum.childData.length > 1 ))">
                                                                                    <!-- superclass level -->
                                                                                    <ul class="nested {{phylChild.key}}-superclass" *ngIf="childTaxanomy.subphylum.length > 0">
                                                                                        <ng-container *ngFor="let subphylum of childTaxanomy.subphylum">
                                                                                            <ng-container *ngIf="phylChild.key === subphylum.parent">
                                                                                                <li *ngFor="let subphylumChild of subphylum.childData; let i = index">
                                                                                                    <i *ngIf="((subphylum.childData.length == 1 && subphylumChild.key != 'Other') || (subphylum.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, subphylum.rank, subphylumChild.key, 'class')"></i>
                                                                                                    <span *ngIf="((subphylum.childData.length == 1 && subphylumChild.key != 'Other') || (subphylum.childData.length > 1 ))" class="caret" id="{{subphylumChild.key}}-{{subphylum.rank}}" (dblclick)="filterTaxonomy(subphylum.rank, subphylumChild.key, 'class',subphylumChild.commonName.buckets[0].key,subphylumChild.taxId.buckets[0].key)">
                                                                                                            {{subphylumChild.key}}
                                                                                                        ({{subphylumChild.commonName.buckets[0].key}})
                                                                                                        </span>
                                                                                                    <span *ngIf="((subphylum.childData.length == 1 && subphylumChild.key != 'Other') || (subphylum.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'" >{{subphylumChild.doc_count}}</span></span>
                                                                                                    <hr *ngIf="((subphylum.childData.length == 1 && subphylumChild.key != 'Other') || (subphylum.childData.length > 1 ))">
                                                                                                    <!-- class level -->
                                                                                                    <ul class="nested {{subphylumChild.key}}-class" *ngIf="childTaxanomy.superclass.length > 0">
                                                                                                        <ng-container *ngFor="let superclass of childTaxanomy.superclass">
                                                                                                            <ng-container *ngIf="subphylumChild.key === superclass.parent">
                                                                                                                <li *ngFor="let superclassChild of superclass.childData; let i = index">
                                                                                                                    <i *ngIf="((superclass.childData.length == 1 && superclassChild.key != 'Other') || (superclass.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, superclass.rank, superclassChild.key, 'infraclass')"></i>
                                                                                                                    <span *ngIf="((superclass.childData.length == 1 && superclassChild.key != 'Other') || (superclass.childData.length > 1 ))" class="caret" id="{{superclassChild.key}}-{{superclass.rank}}" (dblclick)="filterTaxonomy(superclass.rank, superclassChild.key, 'infraclass',superclassChild.commonName.buckets[0].key ,superclassChild.taxId.buckets[0].key)">
                                                                                                                            {{superclassChild.key}}
                                                                                                                        ({{superclassChild.commonName.buckets[0].key}})
                                                                                                                        </span>
                                                                                                                    <span *ngIf="((superclass.childData.length == 1 && superclassChild.key != 'Other') || (superclass.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'" >{{superclassChild.doc_count}}</span></span>
                                                                                                                    <hr *ngIf="((superclass.childData.length == 1 && superclassChild.key != 'Other') || (superclass.childData.length > 1 ))">
                                                                                                                    <!-- infraclass level -->
                                                                                                                    <ul class="nested {{superclassChild.key}}-infraclass" *ngIf="childTaxanomy.class.length > 0">
                                                                                                                        <ng-container *ngFor="let class of childTaxanomy.class">
                                                                                                                            <ng-container *ngIf="superclassChild.key === class.parent">
                                                                                                                                <li *ngFor="let classChild of class.childData; let i = index">
                                                                                                                                    <i *ngIf="((class.childData.length == 1 && classChild.key != 'Other') || (class.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, class.rank, classChild.key, 'cohort')"></i>
                                                                                                                                    <span *ngIf="((class.childData.length == 1 && classChild.key != 'Other') || (class.childData.length > 1 ))" class="caret" id="{{classChild.key}}-{{class.rank}}" (dblclick)="filterTaxonomy(class.rank, classChild.key, 'cohort',classChild.commonName.buckets[0].key ,classChild.taxId.buckets[0].key)">
                                                                                                                                            {{classChild.key}}
                                                                                                                                        ({{classChild.commonName.buckets[0].key}})
                                                                                                                                        </span>
                                                                                                                                    <span *ngIf="((class.childData.length == 1 && classChild.key != 'Other') || (class.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'" >{{classChild.doc_count}}</span></span>
                                                                                                                                    <hr *ngIf="((class.childData.length == 1 && classChild.key != 'Other') || (class.childData.length > 1 ))">
                                                                                                                                    <!-- cohort level -->
                                                                                                                                    <ul class="nested {{classChild.key}}-cohort" *ngIf="childTaxanomy.infraclass.length > 0">
                                                                                                                                        <ng-container *ngFor="let infraclass of childTaxanomy.infraclass">
                                                                                                                                            <ng-container *ngIf="classChild.key === infraclass.parent">
                                                                                                                                                <li *ngFor="let infraclassChild of infraclass.childData; let i = index">
                                                                                                                                                    <i *ngIf="((infraclass.childData.length == 1 && infraclassChild.key != 'Other') || (infraclass.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, infraclass.rank, infraclassChild.key, 'subcohort')"></i>
                                                                                                                                                    <span *ngIf="((infraclass.childData.length == 1 && infraclassChild.key != 'Other') || (infraclass.childData.length > 1 ))" class="caret" id="{{infraclassChild.key}}-{{infraclass.rank}}" (dblclick)="filterTaxonomy(infraclass.rank, infraclassChild.key, 'subcohort',infraclassChild.commonName.buckets[0].key,infraclassChild.taxId.buckets[0].key)">
                                                                                                                                                            {{infraclassChild.key}}
                                                                                                                                                        ({{infraclassChild.commonName.buckets[0].key}})
                                                                                                                                                        </span>
                                                                                                                                                    <span *ngIf="((infraclass.childData.length == 1 && infraclassChild.key != 'Other') || (infraclass.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{infraclassChild.doc_count}}</span></span>
                                                                                                                                                    <hr *ngIf="((infraclass.childData.length == 1 && infraclassChild.key != 'Other') || (infraclass.childData.length > 1 ))">
                                                                                                                                                    <!-- subcohort level -->
                                                                                                                                                    <ul class="nested {{infraclassChild.key}}-subcohort" *ngIf="childTaxanomy.cohort.length > 0">
                                                                                                                                                        <ng-container *ngFor="let cohort of childTaxanomy.cohort">
                                                                                                                                                            <ng-container *ngIf="infraclassChild.key === cohort.parent">
                                                                                                                                                                <li *ngFor="let cohortChild of cohort.childData; let i = index">
                                                                                                                                                                    <i *ngIf="((cohort.childData.length == 1 && cohortChild.key != 'Other') || (cohort.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, cohort.rank, cohortChild.key, 'superorder')"></i>
                                                                                                                                                                    <span *ngIf="((cohort.childData.length == 1 && cohortChild.key != 'Other') || (cohort.childData.length > 1 ))" class="caret" id="{{cohortChild.key}}-{{cohort.rank}}" (dblclick)="filterTaxonomy(cohort.rank, cohortChild.key, 'superorder',cohortChild.commonName.buckets[0].key, cohortChild.taxId.buckets[0].key)">
                                                                                                                                                                            {{cohortChild.key}}
                                                                                                                                                                        ({{cohortChild.commonName.buckets[0].key}})
                                                                                                                                                                        </span>
                                                                                                                                                                    <span *ngIf="((cohort.childData.length == 1 && cohortChild.key != 'Other') || (cohort.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{cohortChild.doc_count}}</span></span>
                                                                                                                                                                    <hr *ngIf="((cohort.childData.length == 1 && cohortChild.key != 'Other') || (cohort.childData.length > 1 ))">
                                                                                                                                                                    <!-- superorder level -->
                                                                                                                                                                    <ul class="nested {{cohortChild.key}}-superorder" *ngIf="childTaxanomy.subcohort.length > 0">
                                                                                                                                                                        <ng-container *ngFor="let subcohort of childTaxanomy.subcohort">
                                                                                                                                                                            <ng-container *ngIf="cohortChild.key === subcohort.parent">
                                                                                                                                                                                <li *ngFor="let subcohortChild of subcohort.childData; let i = index">
                                                                                                                                                                                    <i *ngIf="((subcohort.childData.length == 1 && subcohortChild.key != 'Other') || (subcohort.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, subcohort.rank, subcohortChild.key, 'order')"></i>
                                                                                                                                                                                    <span *ngIf="((subcohort.childData.length == 1 && subcohortChild.key != 'Other') || (subcohort.childData.length > 1 ))" class="caret" id="{{subcohortChild.key}}-{{subcohort.rank}}" (dblclick)="filterTaxonomy(subcohort.rank, subcohortChild.key, 'order',subcohortChild.commonName.buckets[0].key, subcohortChild.taxId.buckets[0].key)">
                                                                                                                                                                                            {{subcohortChild.key}}
                                                                                                                                                                                        ({{subcohortChild.commonName.buckets[0].key}})
                                                                                                                                                                                        </span>
                                                                                                                                                                                    <span *ngIf="((subcohort.childData.length == 1 && subcohortChild.key != 'Other') || (subcohort.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{subcohortChild.doc_count}}</span></span>
                                                                                                                                                                                    <hr *ngIf="((subcohort.childData.length == 1 && subcohortChild.key != 'Other') || (subcohort.childData.length > 1 ))">
                                                                                                                                                                                    <!-- order level -->
                                                                                                                                                                                    <ul class="nested {{subcohortChild.key}}-order" *ngIf="childTaxanomy.superorder.length > 0">
                                                                                                                                                                                        <ng-container *ngFor="let superorder of childTaxanomy.superorder">
                                                                                                                                                                                            <ng-container *ngIf="subcohortChild.key === superorder.parent">
                                                                                                                                                                                                <li *ngFor="let superorderChild of superorder.childData; let i = index">
                                                                                                                                                                                                    <i *ngIf="((superorder.childData.length == 1 && superorderChild.key != 'Other') || (superorder.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, superorder.rank, superorderChild.key, 'suborder')"></i>
                                                                                                                                                                                                    <span *ngIf="((superorder.childData.length == 1 && superorderChild.key != 'Other') || (superorder.childData.length > 1 ))" class="caret" id="{{superorderChild.key}}-{{superorder.rank}}" (dblclick)="filterTaxonomy(superorder.rank, superorderChild.key, 'parvorder', superorderChild.commonName.buckets[0].key,superorderChild.taxId.buckets[0].key)">
                                                                                                                                                                                                            {{superorderChild.key}}
                                                                                                                                                                                                        ({{superorderChild.commonName.buckets[0].key}})
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    <span *ngIf="((superorder.childData.length == 1 && superorderChild.key != 'Other') || (superorder.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{superorderChild.doc_count}}</span></span>
                                                                                                                                                                                                    <hr *ngIf="((superorder.childData.length == 1 && superorderChild.key != 'Other') || (superorder.childData.length > 1 ))">
                                                                                                                                                                                                    <!-- suborder level -->
                                                                                                                                                                                                    <ul class="nested {{superorderChild.key}}-suborder" *ngIf="childTaxanomy.order.length > 0">
                                                                                                                                                                                                        <ng-container *ngFor="let order of childTaxanomy.order">
                                                                                                                                                                                                            <ng-container *ngIf="superorderChild.key === order.parent">
                                                                                                                                                                                                                <li *ngFor="let orderChild of order.childData; let i = index">
                                                                                                                                                                                                                    <i *ngIf="((order.childData.length == 1 && orderChild.key != 'Other') || (order.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, order.rank, orderChild.key, 'infraorder')"></i>
                                                                                                                                                                                                                    <span *ngIf="((order.childData.length == 1 && orderChild.key != 'Other') || (order.childData.length > 1 ))" class="caret" id="{{orderChild.key}}-{{order.rank}}" (dblclick)="filterTaxonomy(order.rank, orderChild.key, 'infraorder',orderChild.commonName.buckets[0].key,orderChild.taxId.buckets[0].key)">
                                                                                                                                                                                                                            {{orderChild.key}}
                                                                                                                                                                                                                        ({{orderChild.commonName.buckets[0].key}})
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    <span *ngIf="((order.childData.length == 1 && orderChild.key != 'Other') || (order.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{orderChild.doc_count}}</span></span>
                                                                                                                                                                                                                    <hr *ngIf="((order.childData.length == 1 && orderChild.key != 'Other') || (order.childData.length > 1 ))">
                                                                                                                                                                                                                    <!-- infraorder level -->
                                                                                                                                                                                                                    <ul class="nested {{orderChild.key}}-infraorder" *ngIf="childTaxanomy.suborder.length > 0">
                                                                                                                                                                                                                        <ng-container *ngFor="let parvorder of childTaxanomy.suborder">
                                                                                                                                                                                                                            <ng-container *ngIf="orderChild.key === parvorder.parent">
                                                                                                                                                                                                                                <li *ngFor="let parvorderChild of parvorder.childData; let i = index">
                                                                                                                                                                                                                                    <i *ngIf="((parvorder.childData.length == 1 && parvorderChild.key != 'Other') || (parvorder.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, parvorder.rank, parvorderChild.key, 'parvorder')"></i>
                                                                                                                                                                                                                                    <span *ngIf="((parvorder.childData.length == 1 && parvorderChild.key != 'Other') || (parvorder.childData.length > 1 ))" class="caret" id="{{parvorderChild.key}}-{{parvorder.rank}}" (dblclick)="filterTaxonomy(parvorder.rank, parvorderChild.key, 'parvorder',parvorderChild.commonName.buckets[0].key,parvorderChild.taxId.buckets[0].key)">
                                                                                                                                                                                                                                            {{parvorderChild.key}}
                                                                                                                                                                                                                                        ({{parvorderChild.commonName.buckets[0].key}})
                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                    <span *ngIf="((parvorder.childData.length == 1 && parvorderChild.key != 'Other') || (parvorder.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{parvorderChild.doc_count}}</span></span>
                                                                                                                                                                                                                                    <hr *ngIf="((parvorder.childData.length == 1 && parvorderChild.key != 'Other') || (parvorder.childData.length > 1 ))">
                                                                                                                                                                                                                                    <!-- parvorder level -->
                                                                                                                                                                                                                                    <ul class="nested {{parvorderChild.key}}-parvorder" *ngIf="childTaxanomy.infraorder.length > 0">
                                                                                                                                                                                                                                        <ng-container *ngFor="let suborder of childTaxanomy.infraorder">
                                                                                                                                                                                                                                            <ng-container *ngIf="parvorderChild.key === suborder.parent">
                                                                                                                                                                                                                                                <li *ngFor="let suborderChild of suborder.childData; let i = index">
                                                                                                                                                                                                                                                    <i *ngIf="((suborder.childData.length == 1 && suborderChild.key != 'Other') || (suborder.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, suborder.rank, suborderChild.key, 'section')"></i>
                                                                                                                                                                                                                                                    <span *ngIf="((suborder.childData.length == 1 && suborderChild.key != 'Other') || (suborder.childData.length > 1 ))" class="caret" id="{{suborderChild.key}}-{{suborder.rank}}" (dblclick)="filterTaxonomy(suborder.rank, suborderChild.key, 'section',suborderChild.commonName.buckets[0].key,suborderChild.taxId.buckets[0].key)">
                                                                                                                                                                                                                                                            {{suborderChild.key}}
                                                                                                                                                                                                                                                        ({{suborderChild.commonName.buckets[0].key}})
                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                                    <span *ngIf="((suborder.childData.length == 1 && suborderChild.key != 'Other') || (suborder.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{suborderChild.doc_count}}</span></span>
                                                                                                                                                                                                                                                    <hr *ngIf="((suborder.childData.length == 1 && suborderChild.key != 'Other') || (suborder.childData.length > 1 ))">
                                                                                                                                                                                                                                                    <!-- section level -->
                                                                                                                                                                                                                                                    <ul class="nested {{suborderChild.key}}-section" *ngIf="childTaxanomy.parvorder.length > 0">
                                                                                                                                                                                                                                                        <ng-container *ngFor="let infraorder of childTaxanomy.parvorder">
                                                                                                                                                                                                                                                            <ng-container *ngIf="suborderChild.key === infraorder.parent">
                                                                                                                                                                                                                                                                <li *ngFor="let infraorderChild of infraorder.childData; let i = index">
                                                                                                                                                                                                                                                                    <i *ngIf="((infraorder.childData.length == 1 && infraorderChild.key != 'Other') || (infraorder.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, infraorder.rank, infraorderChild.key, 'subsection')"></i>
                                                                                                                                                                                                                                                                    <span *ngIf="((infraorder.childData.length == 1 && infraorderChild.key != 'Other') || (infraorder.childData.length > 1 ))" class="caret" id="{{infraorderChild.key}}-{{infraorder.rank}}" (dblclick)="filterTaxonomy(infraorder.rank, infraorderChild.key, 'subsection',infraorderChild.commonName.buckets[0].key,infraorderChild.taxId.buckets[0].key)">
                                                                                                                                                                                                                                                                            {{infraorderChild.key}}
                                                                                                                                                                                                                                                                        ({{infraorderChild.commonName.buckets[0].key}})
                                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                                                    <span *ngIf="((infraorder.childData.length == 1 && infraorderChild.key != 'Other') || (infraorder.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{infraorderChild.doc_count}}</span></span>
                                                                                                                                                                                                                                                                    <hr *ngIf="((infraorder.childData.length == 1 && infraorderChild.key != 'Other') || (infraorder.childData.length > 1 ))">
                                                                                                                                                                                                                                                                    <!-- subsection level -->
                                                                                                                                                                                                                                                                    <ul class="nested {{infraorderChild.key}}-subsection" *ngIf="childTaxanomy.section.length > 0">
                                                                                                                                                                                                                                                                        <ng-container *ngFor="let section of childTaxanomy.section">
                                                                                                                                                                                                                                                                            <ng-container *ngIf="infraorderChild.key === section.parent">
                                                                                                                                                                                                                                                                                <li *ngFor="let sectionChild of section.childData; let i = index">
                                                                                                                                                                                                                                                                                    <i *ngIf="((section.childData.length == 1 && sectionChild.key != 'Other') || (section.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, section.rank, sectionChild.key, 'superfamily')"></i>
                                                                                                                                                                                                                                                                                    <span *ngIf="((section.childData.length == 1 && sectionChild.key != 'Other') || (section.childData.length > 1 ))" class="caret" id="{{sectionChild.key}}-{{section.rank}}" (dblclick)="filterTaxonomy(section.rank, sectionChild.key, 'superfamily',sectionChild.commonName.buckets[0].key,sectionChild.taxId.buckets[0].key)">
                                                                                                                                                                                                                                                                                            {{sectionChild.key}}
                                                                                                                                                                                                                                                                                        ({{sectionChild.commonName.buckets[0].key}})
                                                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                                                                    <span *ngIf="((section.childData.length == 1 && sectionChild.key != 'Other') || (section.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{sectionChild.doc_count}}</span></span>
                                                                                                                                                                                                                                                                                    <hr *ngIf="((section.childData.length == 1 && sectionChild.key != 'Other') || (section.childData.length > 1 ))">
                                                                                                                                                                                                                                                                                    <!-- superfamily level -->
                                                                                                                                                                                                                                                                                    <ul class="nested {{sectionChild.key}}-superfamily" *ngIf="childTaxanomy.subsection.length > 0">
                                                                                                                                                                                                                                                                                        <ng-container *ngFor="let subsection of childTaxanomy.subsection">
                                                                                                                                                                                                                                                                                            <ng-container *ngIf="sectionChild.key === subsection.parent">
                                                                                                                                                                                                                                                                                                <li *ngFor="let subsectionChild of subsection.childData; let i = index">
                                                                                                                                                                                                                                                                                                    <i *ngIf="((subsection.childData.length == 1 && subsectionChild.key != 'Other') || (subsection.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, subsection.rank, subsectionChild.key, 'family')"></i>
                                                                                                                                                                                                                                                                                                    <span *ngIf="((subsection.childData.length == 1 && subsectionChild.key != 'Other') || (subsection.childData.length > 1 ))" class="caret" id="{{subsectionChild.key}}-{{subsection.rank}}" (dblclick)="filterTaxonomy(subsection.rank, subsectionChild.key, 'family',subsectionChild.commonName.buckets[0].key,subsectionChild.taxId.buckets[0].key)">
                                                                                                                                                                                                                                                                                                            {{subsectionChild.key}}
                                                                                                                                                                                                                                                                                                        ({{subsectionChild.commonName.buckets[0].key}})
                                                                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                                                                                    <span *ngIf="((subsection.childData.length == 1 && subsectionChild.key != 'Other') || (subsection.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{subsectionChild.doc_count}}</span></span>
                                                                                                                                                                                                                                                                                                    <hr *ngIf="((subsection.childData.length == 1 && subsectionChild.key != 'Other') || (subsection.childData.length > 1 ))">
                                                                                                                                                                                                                                                                                                    <!-- family level -->
                                                                                                                                                                                                                                                                                                    <ul class="nested {{subsectionChild.key}}-family" *ngIf="childTaxanomy.superfamily.length > 0">
                                                                                                                                                                                                                                                                                                        <ng-container *ngFor="let superfamily of childTaxanomy.superfamily">
                                                                                                                                                                                                                                                                                                            <ng-container *ngIf="subsectionChild.key === superfamily.parent">
                                                                                                                                                                                                                                                                                                                <li *ngFor="let superfamilyChild of superfamily.childData; let i = index">
                                                                                                                                                                                                                                                                                                                    <i *ngIf="((superfamily.childData.length == 1 && superfamilyChild.key != 'Other') || (superfamily.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, superfamily.rank, superfamilyChild.key, 'subfamily')"></i>
                                                                                                                                                                                                                                                                                                                    <span *ngIf="((superfamily.childData.length == 1 && superfamilyChild.key != 'Other') || (superfamily.childData.length > 1 ))" class="caret" id="{{superfamilyChild.key}}-{{superfamily.rank}}" (dblclick)="filterTaxonomy(superfamily.rank, superfamilyChild.key, 'subfamily',superfamilyChild.commonName.buckets[0].key ,superfamilyChild.taxId.buckets[0].key)">
                                                                                                                                                                                                                                                                                                                            {{superfamilyChild.key}}
                                                                                                                                                                                                                                                                                                                        ({{superfamilyChild.commonName.buckets[0].key}})
                                                                                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                                                                                                    <span *ngIf="((superfamily.childData.length == 1 && superfamilyChild.key != 'Other') || (superfamily.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{superfamilyChild.doc_count}}</span></span>
                                                                                                                                                                                                                                                                                                                    <hr *ngIf="((superfamily.childData.length == 1 && superfamilyChild.key != 'Other') || (superfamily.childData.length > 1 ))">
                                                                                                                                                                                                                                                                                                                    <!-- subfamily level -->
                                                                                                                                                                                                                                                                                                                    <ul class="nested {{superfamilyChild.key}}-subfamily" *ngIf="childTaxanomy.family.length > 0">
                                                                                                                                                                                                                                                                                                                        <ng-container *ngFor="let family of childTaxanomy.family">
                                                                                                                                                                                                                                                                                                                            <ng-container *ngIf="superfamilyChild.key === family.parent">
                                                                                                                                                                                                                                                                                                                                <li *ngFor="let familyChild of family.childData; let i = index">
                                                                                                                                                                                                                                                                                                                                    <i *ngIf="((family.childData.length == 1 && familyChild.key != 'Other') || (family.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, family.rank, familyChild.key, 'tribe')"></i>
                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((family.childData.length == 1 && familyChild.key != 'Other') || (family.childData.length > 1 ))" class="caret" id="{{familyChild.key}}-{{family.rank}}" (dblclick)="filterTaxonomy(family.rank, familyChild.key, 'tribe',familyChild.commonName.buckets[0].key,familyChild.taxId.buckets[0].key)">
                                                                                                                                                                                                                                                                                                                                            {{familyChild.key}}
                                                                                                                                                                                                                                                                                                                                        ({{familyChild.commonName.buckets[0].key}})
                                                                                                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((family.childData.length == 1 && familyChild.key != 'Other') || (family.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{familyChild.doc_count}}</span></span>
                                                                                                                                                                                                                                                                                                                                    <hr *ngIf="((family.childData.length == 1 && familyChild.key != 'Other') || (family.childData.length > 1 ))">
                                                                                                                                                                                                                                                                                                                                    <!-- tribe level -->
                                                                                                                                                                                                                                                                                                                                    <ul class="nested {{familyChild.key}}-tribe" *ngIf="childTaxanomy.subfamily.length > 0">
                                                                                                                                                                                                                                                                                                                                        <ng-container *ngFor="let subfamily of childTaxanomy.subfamily">
                                                                                                                                                                                                                                                                                                                                            <ng-container *ngIf="familyChild.key === subfamily.parent">
                                                                                                                                                                                                                                                                                                                                                <li *ngFor="let subfamilyChild of subfamily.childData; let i = index">
                                                                                                                                                                                                                                                                                                                                                    <i *ngIf="((subfamily.childData.length == 1 && subfamilyChild.key != 'Other') || (subfamily.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, subfamily.rank, subfamilyChild.key, 'subtribe')"></i>
                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((subfamily.childData.length == 1 && subfamilyChild.key != 'Other') || (subfamily.childData.length > 1 ))" class="caret" id="{{subfamilyChild.key}}-{{subfamily.rank}}" (dblclick)="filterTaxonomy(subfamily.rank, subfamilyChild.key, 'subtribe',subfamilyChild.commonName.buckets[0].key ,subfamilyChild.taxId.buckets[0].key)">
                                                                                                                                                                                                                                                                                                                                                            {{subfamilyChild.key}}
                                                                                                                                                                                                                                                                                                                                                        ({{subfamilyChild.commonName.buckets[0].key}})
                                                                                                                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((subfamily.childData.length == 1 && subfamilyChild.key != 'Other') || (subfamily.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{subfamilyChild.doc_count}}</span></span>
                                                                                                                                                                                                                                                                                                                                                    <hr *ngIf="((subfamily.childData.length == 1 && subfamilyChild.key != 'Other') || (subfamily.childData.length > 1 ))">
                                                                                                                                                                                                                                                                                                                                                    <!-- subtribe level -->
                                                                                                                                                                                                                                                                                                                                                    <ul class="nested {{subfamilyChild.key}}-subtribe" *ngIf="childTaxanomy.tribe.length > 0">
                                                                                                                                                                                                                                                                                                                                                        <ng-container *ngFor="let tribe of childTaxanomy.tribe">
                                                                                                                                                                                                                                                                                                                                                            <ng-container *ngIf="subfamilyChild.key === tribe.parent">
                                                                                                                                                                                                                                                                                                                                                                <li *ngFor="let tribeChild of tribe.childData; let i = index">
                                                                                                                                                                                                                                                                                                                                                                    <i *ngIf="((tribe.childData.length == 1 && tribeChild.key != 'Other') || (tribe.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, tribe.rank, tribeChild.key, 'genus')"></i>
                                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((tribe.childData.length == 1 && tribeChild.key != 'Other') || (tribe.childData.length > 1 ))" class="caret" id="{{tribeChild.key}}-{{tribe.rank}}" (dblclick)="filterTaxonomy(tribe.rank, tribeChild.key, 'genus',tribeChild.commonName.buckets[0].key ,tribeChild.taxId.buckets[0].key)">
                                                                                                                                                                                                                                                                                                                                                                            {{tribeChild.key}}
                                                                                                                                                                                                                                                                                                                                                                        ({{tribeChild.commonName.buckets[0].key}})
                                                                                                                                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((tribe.childData.length == 1 && tribeChild.key != 'Other') || (tribe.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{tribeChild.doc_count}}</span></span>
                                                                                                                                                                                                                                                                                                                                                                    <hr *ngIf="((tribe.childData.length == 1 && tribeChild.key != 'Other') || (tribe.childData.length > 1 ))">
                                                                                                                                                                                                                                                                                                                                                                    <!-- genus level -->
                                                                                                                                                                                                                                                                                                                                                                    <ul class="nested {{tribeChild.key}}-genus" *ngIf="childTaxanomy.subtribe.length > 0">
                                                                                                                                                                                                                                                                                                                                                                        <ng-container *ngFor="let subtribe of childTaxanomy.subtribe">
                                                                                                                                                                                                                                                                                                                                                                            <ng-container *ngIf="tribeChild.key === subtribe.parent">
                                                                                                                                                                                                                                                                                                                                                                                <li *ngFor="let subtribeChild of subtribe.childData; let i = index">
                                                                                                                                                                                                                                                                                                                                                                                    <i *ngIf="((subtribe.childData.length == 1 && subtribeChild.key != 'Other') || (subtribe.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, subtribe.rank, subtribeChild.key, 'series')"></i>
                                                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((subtribe.childData.length == 1 && subtribeChild.key != 'Other') || (subtribe.childData.length > 1 ))" class="caret" id="{{subtribeChild.key}}-{{subtribe.rank}}" (dblclick)="filterTaxonomy(subtribe.rank, subtribeChild.key, 'series',subtribeChild.commonName.buckets[0].key ,subtribeChild.taxId.buckets[0].key)">
                                                                                                                                                                                                                                                                                                                                                                                            {{subtribeChild.key}}
                                                                                                                                                                                                                                                                                                                                                                                        ({{subtribeChild.commonName.buckets[0].key}})
                                                                                                                                                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((subtribe.childData.length == 1 && subtribeChild.key != 'Other') || (subtribe.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{subtribeChild.doc_count}}</span></span>
                                                                                                                                                                                                                                                                                                                                                                                    <hr *ngIf="((subtribe.childData.length == 1 && subtribeChild.key != 'Other') || (subtribe.childData.length > 1 ))">
                                                                                                                                                                                                                                                                                                                                                                                    <!-- series level -->
                                                                                                                                                                                                                                                                                                                                                                                    <ul class="nested {{subtribeChild.key}}-series" *ngIf="childTaxanomy.genus.length > 0">
                                                                                                                                                                                                                                                                                                                                                                                        <ng-container *ngFor="let genus of childTaxanomy.genus">
                                                                                                                                                                                                                                                                                                                                                                                            <ng-container *ngIf="subtribeChild.key === genus.parent">
                                                                                                                                                                                                                                                                                                                                                                                                <li *ngFor="let genusChild of genus.childData; let i = index">
                                                                                                                                                                                                                                                                                                                                                                                                    <i *ngIf="((genus.childData.length == 1 && genusChild.key != 'Other') || (genus.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, genus.rank, genusChild.key, 'subgenus')"></i>
                                                                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((genus.childData.length == 1 && genusChild.key != 'Other') || (genus.childData.length > 1 ))" class="caret" id="{{genusChild.key}}-{{genus.rank}}" (dblclick)="filterTaxonomy(genus.rank, genusChild.key, 'subgenus',genusChild.commonName.buckets[0].key ,genusChild.taxId.buckets[0].key)">
                                                                                                                                                                                                                                                                                                                                                                                                            {{genusChild.key}}
                                                                                                                                                                                                                                                                                                                                                                                                        ({{genusChild.commonName.buckets[0].key}})
                                                                                                                                                                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((genus.childData.length == 1 && genusChild.key != 'Other') || (genus.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{genusChild.doc_count}}</span></span>
                                                                                                                                                                                                                                                                                                                                                                                                    <hr *ngIf="((genus.childData.length == 1 && genusChild.key != 'Other') || (genus.childData.length > 1 ))">
                                                                                                                                                                                                                                                                                                                                                                                                    <!-- subgenus level -->
                                                                                                                                                                                                                                                                                                                                                                                                    <ul class="nested {{genusChild.key}}-subgenus" *ngIf="childTaxanomy.series.length > 0">
                                                                                                                                                                                                                                                                                                                                                                                                        <ng-container *ngFor="let series of childTaxanomy.series">
                                                                                                                                                                                                                                                                                                                                                                                                            <ng-container *ngIf="genusChild.key === series.parent">
                                                                                                                                                                                                                                                                                                                                                                                                                <li *ngFor="let seriesChild of series.childData; let i = index">
                                                                                                                                                                                                                                                                                                                                                                                                                    <i *ngIf="((series.childData.length == 1 && seriesChild.key != 'Other') || (series.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, series.rank, seriesChild.key, 'species_group')"></i>
                                                                                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((series.childData.length == 1 && seriesChild.key != 'Other') || (series.childData.length > 1 ))" class="caret" id="{{seriesChild.key}}-{{series.rank}}" (dblclick)="filterTaxonomy(series.rank, seriesChild.key, 'species_group',seriesChild.commonName.buckets[0].key ,seriesChild.taxId.buckets[0].key)">
                                                                                                                                                                                                                                                                                                                                                                                                                            {{seriesChild.key}}
                                                                                                                                                                                                                                                                                                                                                                                                                        ({{seriesChild.commonName.buckets[0].key}})
                                                                                                                                                                                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((series.childData.length == 1 && seriesChild.key != 'Other') || (series.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{seriesChild.doc_count}}</span></span>
                                                                                                                                                                                                                                                                                                                                                                                                                    <hr *ngIf="((series.childData.length == 1 && seriesChild.key != 'Other') || (series.childData.length > 1 ))">
                                                                                                                                                                                                                                                                                                                                                                                                                    <!-- species_group level -->
                                                                                                                                                                                                                                                                                                                                                                                                                    <ul class="nested {{seriesChild.key}}-species_group" *ngIf="childTaxanomy.subgenus.length > 0">
                                                                                                                                                                                                                                                                                                                                                                                                                        <ng-container *ngFor="let subgenus of childTaxanomy.subgenus">
                                                                                                                                                                                                                                                                                                                                                                                                                            <ng-container *ngIf="seriesChild.key === subgenus.parent">
                                                                                                                                                                                                                                                                                                                                                                                                                                <li *ngFor="let subgenusChild of subgenus.childData; let i = index">
                                                                                                                                                                                                                                                                                                                                                                                                                                    <i *ngIf="((subgenus.childData.length == 1 && subgenusChild.key != 'Other') || (subgenus.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, subgenus.rank, subgenusChild.key, 'species_subgroup')"></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((subgenus.childData.length == 1 && subgenusChild.key != 'Other') || (subgenus.childData.length > 1 ))" class="caret" id="{{subgenusChild.key}}-{{subgenus.rank}}" (dblclick)="filterTaxonomy(subgenus.rank, subgenusChild.key, 'species_subgroup',subgenusChild.commonName.buckets[0].key,subgenusChild.taxId.buckets[0].key)">
                                                                                                                                                                                                                                                                                                                                                                                                                                            {{subgenusChild.key}}
                                                                                                                                                                                                                                                                                                                                                                                                                                        ({{subgenusChild.commonName.buckets[0].key}})
                                                                                                                                                                                                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((subgenus.childData.length == 1 && subgenusChild.key != 'Other') || (subgenus.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{subgenusChild.doc_count}}</span></span>
                                                                                                                                                                                                                                                                                                                                                                                                                                    <hr *ngIf="((subgenus.childData.length == 1 && subgenusChild.key != 'Other') || (subgenus.childData.length > 1 ))">
                                                                                                                                                                                                                                                                                                                                                                                                                                    <!-- species_subgroup level -->
                                                                                                                                                                                                                                                                                                                                                                                                                                    <ul class="nested {{subgenusChild.key}}-species_subgroup" *ngIf="childTaxanomy.species_group.length > 0">
                                                                                                                                                                                                                                                                                                                                                                                                                                        <ng-container *ngFor="let speciesgroup of childTaxanomy.species_group">
                                                                                                                                                                                                                                                                                                                                                                                                                                            <ng-container *ngIf="subgenusChild.key === speciesgroup.parent">
                                                                                                                                                                                                                                                                                                                                                                                                                                                <li *ngFor="let speciesgroupChild of speciesgroup.childData; let i = index">
                                                                                                                                                                                                                                                                                                                                                                                                                                                    <i *ngIf="((speciesgroup.childData.length == 1 && speciesgroupChild.key != 'Other') || (speciesgroup.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, speciesgroup.rank, speciesgroupChild.key, 'species')"></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((speciesgroup.childData.length == 1 && speciesgroupChild.key != 'Other') || (speciesgroup.childData.length > 1 ))" class="caret" id="{{speciesgroupChild.key}}-{{speciesgroup.rank}}" (dblclick)="filterTaxonomy(speciesgroup.rank, speciesgroupChild.key, 'species',speciesgroupChild.commonName.buckets[0].key ,speciesgroupChild.taxId.buckets[0].key)">
                                                                                                                                                                                                                                                                                                                                                                                                                                                            {{speciesgroupChild.key}}
                                                                                                                                                                                                                                                                                                                                                                                                                                                        ({{speciesgroupChild.commonName.buckets[0].key}})
                                                                                                                                                                                                                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((speciesgroup.childData.length == 1 && speciesgroupChild.key != 'Other') || (speciesgroup.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{speciesgroupChild.doc_count}}</span></span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                    <hr *ngIf="((speciesgroup.childData.length == 1 && speciesgroupChild.key != 'Other') || (speciesgroup.childData.length > 1 ))">
                                                                                                                                                                                                                                                                                                                                                                                                                                                    <!-- species level -->
                                                                                                                                                                                                                                                                                                                                                                                                                                                    <ul class="nested {{speciesgroupChild.key}}-species" *ngIf="childTaxanomy.species_subgroup.length > 0">
                                                                                                                                                                                                                                                                                                                                                                                                                                                        <ng-container *ngFor="let speciessubgroup of childTaxanomy.species_subgroup">
                                                                                                                                                                                                                                                                                                                                                                                                                                                            <ng-container *ngIf="speciesgroupChild.key === speciessubgroup.parent">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                <li *ngFor="let speciessubgroupChild of speciessubgroup.childData; let i = index">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <i *ngIf="((speciessubgroup.childData.length == 1 && speciessubgroupChild.key != 'Other') || (speciessubgroup.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, speciessubgroup.rank, speciessubgroupChild.key, 'subspecies')"></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((speciessubgroup.childData.length == 1 && speciessubgroupChild.key != 'Other') || (speciessubgroup.childData.length > 1 ))" class="caret" id="{{speciessubgroupChild.key}}-{{speciessubgroup.rank}}" (dblclick)="filterTaxonomy(speciessubgroup.rank, speciessubgroupChild.key, 'subspecies',speciessubgroupChild.commonName.buckets[0].key,speciessubgroupChild.taxId.buckets[0].key)">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            {{speciessubgroupChild.key}}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ({{speciessubgroupChild.commonName.buckets[0].key}})
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((speciessubgroup.childData.length == 1 && speciessubgroupChild.key != 'Other') || (speciessubgroup.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{speciessubgroupChild.doc_count}}</span></span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <hr *ngIf="((speciessubgroup.childData.length == 1 && speciessubgroupChild.key != 'Other') || (speciessubgroup.childData.length > 1 ))">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <!-- subspecies level -->
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <ul class="nested {{speciessubgroupChild.key}}-subspecies" *ngIf="childTaxanomy.species.length > 0">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <ng-container *ngFor="let species of childTaxanomy.species">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <ng-container *ngIf="speciessubgroupChild.key === species.parent">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <li *ngFor="let speciesChild of species.childData; let i = index">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <i *ngIf="((species.childData.length == 1 && speciesChild.key != 'Other') || (species.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, species.rank, speciesChild.key, 'varietas')"></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((species.childData.length == 1 && speciesChild.key != 'Other') || (species.childData.length > 1 ))" class="caret" id="{{speciesChild.key}}-{{species.rank}}" (dblclick)="filterTaxonomy(species.rank, speciesChild.key, 'varietas',speciesChild.commonName.buckets[0].key,speciesChild.taxId.buckets[0].key)">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            {{speciesChild.key}}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ({{speciesChild.commonName.buckets[0].key}})
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((species.childData.length == 1 && speciesChild.key != 'Other') || (species.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{speciesChild.doc_count}}</span></span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <hr *ngIf="((species.childData.length == 1 && speciesChild.key != 'Other') || (species.childData.length > 1 ))">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <!-- varietas level -->
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <ul class="nested {{speciesChild.key}}-varietas" *ngIf="childTaxanomy.subspecies.length > 0">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <ng-container *ngFor="let subspecies of childTaxanomy.subspecies">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <ng-container *ngIf="speciesChild.key === subspecies.parent">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <li *ngFor="let subspeciesChild of subspecies.childData; let i = index">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <i *ngIf="((subspecies.childData.length == 1 && subspeciesChild.key != 'Other') || (subspecies.childData.length > 1 ))" class="fas fa-plus-circle" (click)="getChildTaxonomyRankEvent($event, subspecies.rank, subspeciesChild.key, 'forma')"></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((subspecies.childData.length == 1 && subspeciesChild.key != 'Other') || (subspecies.childData.length > 1 ))" class="caret" id="{{subspeciesChild.key}}-{{subspecies.rank}}" (dblclick)="filterTaxonomy(subspecies.rank, subspeciesChild.key, 'forma', subspeciesChild.commonName.buckets[0].key,subspeciesChild.taxId.buckets[0].key)">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            {{subspeciesChild.key}}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ({{subspeciesChild.commonName.buckets[0].key}})
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((subspecies.childData.length == 1 && subspeciesChild.key != 'Other') || (subspecies.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{subspeciesChild.doc_count}}</span></span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <hr *ngIf="((subspecies.childData.length == 1 && subspeciesChild.key != 'Other') || (subspecies.childData.length > 1 ))">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <!-- forma level -->
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <ul class="nested {{subspeciesChild.key}}-forma" *ngIf="childTaxanomy.varietas.length > 0">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <ng-container *ngFor="let varietas of childTaxanomy.varietas">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <ng-container *ngIf="subspeciesChild.key === varietas.parent">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <li *ngFor="let varietasChild of varietas.childData; let i = index">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <!-- <i *ngIf="((varietas.childData.length == 1 && varietasChild.key != 'Other') || (varietas.childData.length > 1 ))"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        class="fas fa-plus-circle"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        (click)="getChildTaxonomyRankEvent($event, varietas.rank, varietasChild.key, '')"></i> -->
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((varietas.childData.length == 1 && varietasChild.key != 'Other') || (varietas.childData.length > 1 ))" class="caret" id="{{varietasChild.key}}-{{varietas.rank}}" (dblclick)="filterTaxonomy(varietas.rank, varietasChild.key, 'forma',varietasChild.commonName.buckets[0].key,varietasChild.taxId.buckets[0].key)">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            {{varietasChild.key}}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ({{varietasChild.commonName.buckets[0].key}})
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <span *ngIf="((varietas.childData.length == 1 && varietasChild.key != 'Other') || (varietas.childData.length > 1 ))" class="badge badge-pill badge-info"><span *ngIf="isShowCount === 'true'">{{varietasChild.doc_count}}</span></span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <hr *ngIf="((varietas.childData.length == 1 && varietasChild.key != 'Other') || (varietas.childData.length > 1 ))">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </ng-container>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </ng-container>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    </ul>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </ng-container>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </ng-container>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    </ul>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </ng-container>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </ng-container>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    </ul>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                            </ng-container>
                                                                                                                                                                                                                                                                                                                                                                                                                                                        </ng-container>
                                                                                                                                                                                                                                                                                                                                                                                                                                                    </ul>
                                                                                                                                                                                                                                                                                                                                                                                                                                                </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                            </ng-container>
                                                                                                                                                                                                                                                                                                                                                                                                                                        </ng-container>
                                                                                                                                                                                                                                                                                                                                                                                                                                    </ul>
                                                                                                                                                                                                                                                                                                                                                                                                                                </li>
                                                                                                                                                                                                                                                                                                                                                                                                                            </ng-container>
                                                                                                                                                                                                                                                                                                                                                                                                                        </ng-container>
                                                                                                                                                                                                                                                                                                                                                                                                                    </ul>
                                                                                                                                                                                                                                                                                                                                                                                                                </li>
                                                                                                                                                                                                                                                                                                                                                                                                            </ng-container>
                                                                                                                                                                                                                                                                                                                                                                                                        </ng-container>
                                                                                                                                                                                                                                                                                                                                                                                                    </ul>
                                                                                                                                                                                                                                                                                                                                                                                                </li>
                                                                                                                                                                                                                                                                                                                                                                                            </ng-container>
                                                                                                                                                                                                                                                                                                                                                                                        </ng-container>
                                                                                                                                                                                                                                                                                                                                                                                    </ul>
                                                                                                                                                                                                                                                                                                                                                                                </li>
                                                                                                                                                                                                                                                                                                                                                                            </ng-container>
                                                                                                                                                                                                                                                                                                                                                                        </ng-container>
                                                                                                                                                                                                                                                                                                                                                                    </ul>
                                                                                                                                                                                                                                                                                                                                                                </li>
                                                                                                                                                                                                                                                                                                                                                            </ng-container>
                                                                                                                                                                                                                                                                                                                                                        </ng-container>
                                                                                                                                                                                                                                                                                                                                                    </ul>
                                                                                                                                                                                                                                                                                                                                                </li>
                                                                                                                                                                                                                                                                                                                                            </ng-container>
                                                                                                                                                                                                                                                                                                                                        </ng-container>
                                                                                                                                                                                                                                                                                                                                    </ul>
                                                                                                                                                                                                                                                                                                                                </li>
                                                                                                                                                                                                                                                                                                                            </ng-container>
                                                                                                                                                                                                                                                                                                                        </ng-container>
                                                                                                                                                                                                                                                                                                                    </ul>
                                                                                                                                                                                                                                                                                                                </li>
                                                                                                                                                                                                                                                                                                            </ng-container>
                                                                                                                                                                                                                                                                                                        </ng-container>
                                                                                                                                                                                                                                                                                                    </ul>
                                                                                                                                                                                                                                                                                                </li>
                                                                                                                                                                                                                                                                                            </ng-container>
                                                                                                                                                                                                                                                                                        </ng-container>
                                                                                                                                                                                                                                                                                    </ul>
                                                                                                                                                                                                                                                                                </li>
                                                                                                                                                                                                                                                                            </ng-container>
                                                                                                                                                                                                                                                                        </ng-container>
                                                                                                                                                                                                                                                                    </ul>
                                                                                                                                                                                                                                                                </li>
                                                                                                                                                                                                                                                            </ng-container>
                                                                                                                                                                                                                                                        </ng-container>
                                                                                                                                                                                                                                                    </ul>
                                                                                                                                                                                                                                                </li>
                                                                                                                                                                                                                                            </ng-container>
                                                                                                                                                                                                                                        </ng-container>
                                                                                                                                                                                                                                    </ul>
                                                                                                                                                                                                                                </li>
                                                                                                                                                                                                                            </ng-container>
                                                                                                                                                                                                                        </ng-container>
                                                                                                                                                                                                                    </ul>
                                                                                                                                                                                                                </li>
                                                                                                                                                                                                            </ng-container>
                                                                                                                                                                                                        </ng-container>
                                                                                                                                                                                                    </ul>
                                                                                                                                                                                                </li>
                                                                                                                                                                                            </ng-container>
                                                                                                                                                                                        </ng-container>
                                                                                                                                                                                    </ul>
                                                                                                                                                                                </li>
                                                                                                                                                                            </ng-container>
                                                                                                                                                                        </ng-container>
                                                                                                                                                                    </ul>
                                                                                                                                                                </li>
                                                                                                                                                            </ng-container>
                                                                                                                                                        </ng-container>
                                                                                                                                                    </ul>
                                                                                                                                                </li>
                                                                                                                                            </ng-container>
                                                                                                                                        </ng-container>
                                                                                                                                    </ul>
                                                                                                                                </li>
                                                                                                                            </ng-container>
                                                                                                                        </ng-container>
                                                                                                                    </ul>
                                                                                                                </li>
                                                                                                            </ng-container>
                                                                                                        </ng-container>
                                                                                                    </ul>
                                                                                                </li>
                                                                                            </ng-container>
                                                                                        </ng-container>
                                                                                    </ul>
                                                                                </li>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </ul>
                                                                </li>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ul>
                                                </li>
                                            </ng-container>
                                        </ng-container>
                                    </ul>
                                </li>
                            </ng-container>
                        </ng-container>
                    </ul>
                </ul>
            </div>
        </div>
    </div>
</div>

