<div id="gradient">
<!--    <cookie-law #cookieLaw>-->
<!--        This website requires cookies, and the limited processing of your personal data in order to function. By using the site you are agreeing to this as outlined in our-->
<!--        <a href="https://www.ebi.ac.uk/data-protection/privacy-notice/darwin-tree-of-life-data-portal" target="_blank">Privacy-->
<!--        Notice</a>-->
<!--    </cookie-law>-->
    <app-header></app-header>
    <!-- <div class="alert alert-danger alert-dismissible fade show" style=" margin-bottom: 0px; text-align: center; ">
        Backend is not accessible at the moment due to issue with EMBL-EBI cloud infrastructure. We are aware of the issue and our systems teams are actively working to resolve this.
    </div> -->
    <router-outlet></router-outlet>
</div>