<div id="overlay"></div>
<div id="dynamic">
    <ngx-spinner bdColor="rgba(0,0,0,0.9)" [fullScreen]="true" size="large" color="#fff" type="ball-clip-rotate-pulse">
    </ngx-spinner>
    <div class="container" style="padding-top: 20px;">
        <div class="row">
            <div class="col-md-2">
                <div class="row">
                    <div class="col-md-12 col-sm-6">
                      
                                <div class="card bg-lite mb-3 filter-top">
                                    <h3 class="card-header filter-heading" >Article Type</h3>
                                <ul class="list-group">
                                    @for(filter of articleTypeFilters; track $index){
                                        <li class="biosample list-group-item d-flex justify-content-between align-items-center
                                        biosamples-inactive" [ngClass]="checkFilterIsActive(filter.key)"
                                        (click)="onFilterClick(filter.label + '-' + filter.key)" style="cursor: pointer">
                                        <span>{{filter.key}}</span>
                                        <span class="badge badge-pill badge-info">{{filter.doc_count}}</span>
                                        </li>
                                    }
                                
                                </ul>

                                </div>
                    </div>
                    <div class="col-md-12 col-sm-6">
                       
                        <div class="card bg-lite mb-3 filter-top">
                            <h3 class="card-header filter-heading" >Journal</h3>
                            <ul class="list-group" >
                                @for(filter of journalNameFilters; track $index ){

                                    <li class="biosample list-group-item d-flex justify-content-between align-items-center
                                            biosamples-inactive" [ngClass]="checkFilterIsActive(filter.key)"
                                        (click)="onFilterClick(filter.label + '-' + filter.key)" style="cursor: pointer">
                                        <span>{{filter.key}}</span>
                                        <span class="badge badge-pill badge-info">{{filter.doc_count}}</span>
                                    </li>
                                }
                            
                            
                            </ul>
                        </div>

                    </div>
                    <div class="col-md-12 col-sm-6">
                        
                                <div class="card bg-lite mb-3 filter-top">
                                    <h3 class="card-header filter-heading" >Publication Year</h3>
                        <ul class="list-group" >
                            @for(filter of publicationYearFilters; track $index){
                                <li class="biosample list-group-item d-flex justify-content-between align-items-center
                                biosamples-inactive" [ngClass]="checkFilterIsActive(filter.key)"
                                (click)="onFilterClick(filter.label + '-' + filter.key)" style="cursor: pointer">
                                <span>{{filter.key}}</span>
                                <span class="badge badge-pill badge-info">{{filter.doc_count}}</span>
                                </li>
                            }
                        
                        </ul>
                                </div>
                    </div>
                </div>

            </div>

            <div class="col-md-10" >
                <h1 class="page-header">Publications</h1>
                <br />
                <div class="column-header" >
                    <!-- @if (isLoadingResults || isRateLimitReached) {
                        <div class="example-loading-shade">
                            @if (isLoadingResults) {
                                <mat-spinner></mat-spinner>
                            }
                            @if (isRateLimitReached) {
                                <div class="example-rate-limit-reached">
                                    Something went wrong, please try again!
                                </div>
                            }

                        </div>
                    } -->
                    <div class="example-table-container">
                        @if(activeFilters.length > 0){
                            <h1 >Filters: </h1>
                        }
                    @if((activeFilters.length > 0)){
                        @for(field of activeFilters;track $index){
                            
                                <button type="button" class="btn btn-success">
                                    {{field}} <span class="badge badge-light" (click)="onFilterClick(field)">X</span>
                                </button>
                            
                        
                        }
                        <button class="btn btn-primary" type="button" (click)="removeFilter()">Remove all filters
                        </button>
                    }
                        <br>
                        <br>

                    </div>
                </div>
                <br>
                <mat-form-field class="search_field">
                    <mat-label>Search</mat-label>
                    <input matInput (keyup)="applyFilter($event)" [(ngModel)]='searchValue'
                           placeholder="ex. Salmo trutta" #input>
                </mat-form-field>

                <table mat-table [dataSource]="data" class="data-table"
                       matSort matSortActive="pubYear"
                       matSortDisableClear
                       matSortDirection="asc" >

                    <ng-container matColumnDef="title">
                        <mat-header-cell *matHeaderCellDef mat-sort-header
                                         >Title</mat-header-cell>
                        <mat-cell *matCellDef="let element" >
                            <a [href]="element['_source']['url']" [title]="element['_source']['title']"
                               [innerHTML]="element['_source']['title']" >

                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="journalTitle">
                        <mat-header-cell   *matHeaderCellDef mat-sort-header [ngClass]="'w-150 th-left'">Journal</mat-header-cell>
                        <mat-cell *matCellDef="let element"
                                 >{{getJournalName(element['_source'])}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="pubYear">
                        <mat-header-cell *matHeaderCellDef
                                         mat-sort-header  [ngClass]="'w-120 th-center'">Publication Year</mat-header-cell>
                        <mat-cell *matCellDef="let element"
                                  >{{getYear(element['_source'])}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="organism_name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-150 th-left'">Organism Name</mat-header-cell>
                        <mat-cell *matCellDef="let element"
                                 >
                            <a [routerLink]="['/data/root/details/', element['_source']['organism_name']]">
                                {{element['_source']['organism_name']}}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="study_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header >Study ID</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a href="https://www.ebi.ac.uk/ena/browser/view/{{element['_source']['study_id']}}">
                                {{element['_source']['study_id']}}
                            </a>
                        </mat-cell>
                    </ng-container>




                    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: columns;"></mat-row>
                </table>
                <mat-paginator [length]="resultsLength"
                               [pageSizeOptions]="[15, 50, 100]"
                               aria-label="Select page"
                               showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>
