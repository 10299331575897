<div id="dynamic">
    <div class="container" style="padding-top: 20px;">
        <div class="row">
            <div class="col-md-12">
                <h1 class="page-header text-center">Contact us</h1>
            </div>
        </div>

        <div class="text-center">
            <p class="mb64">To contact us about any aspect of the Aquatic Symbiosis Project programme please use one of the following methods.<br>
                <br>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="text-center">
                    <!-- <p class="mb0"><strong>Phone :</strong></p>
                    <p class="mb32"><a href="tel:+44 (0)1223 496925">+44 (0)1223 496925</a></p>
                    <p class="mb0"><strong>Email :</strong></p>
                    <p class="mb32"><a href="mailto:contact@darwintreeoflife.org">contact@darwintreeoflife.org</a></p> -->
                    <p class="mb0"><strong>DToL data coordination centre helpdesk :</strong></p>
                    <p class="mb32"><a href="mailto:asg-dcc@ebi.ac.uk">asg-dcc&#64;ebi.ac.uk</a></p>
                    <!-- <p class="mb0"><strong>Address :</strong></p>
                    <p class="mb32"> Aquatic Symbiosis Project<br> Wellcome Sanger Institute<br> Wellcome Genome Campus
                        <br> Hinxton
                        <br> Cambridgeshire
                        <br> CB10 1SA
                    </p> -->
                </div>
            </div>
        </div>
    </div>
</div>
