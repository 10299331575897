<div id="dynamic">
    <div class="container" style="padding-top: 65px;">
        <h1 class="text-center page-header">{{bioSampleObj?.organism}}</h1>
        <dl class="row">
            <dt class="col-md-3" *ngIf="bioSampleObj?.commonName">Name:</dt>
            <dd class="col-md-9" *ngIf="bioSampleObj?.commonName">{{bioSampleObj?.commonName}}</dd>

            <dt class="col-md-3">Organism:</dt>
            <dd class="col-md-9">{{bioSampleObj?.organism}}</dd>
            <!-- <dd class="col-md-6">
            <a class="no-underline badge badge-pill goat-color" target="_blank" href="https://goat.genomehubs.org/records?record_id={{bioSampleObj?.taxId}}&result=taxon">GoaT info</a>
        </dd> -->

            <dt class="col-md-3" *ngIf="bioSampleObj?.sex">Sex:</dt>
            <dd class="col-md-9" *ngIf="bioSampleObj?.sex">{{bioSampleObj?.sex}}</dd>

            <dt class="col-md-3" *ngIf="bioSampleObj?.trackingSystem">Tracking Status:</dt>
            <dd class="col-md-9" *ngIf="bioSampleObj?.trackingSystem">
                <mat-chip-set>
                    <mat-chip [ngStyle]="{'background-color': 'gold'}">{{bioSampleObj?.currentStatus}}</mat-chip>
                </mat-chip-set>
            </dd>

        </dl>
                    <mat-tab-group dynamicHeight #tabgroup>
                        <mat-tab label="Data" *ngIf=" dataSourceAnnotationCount != 0 || dataSourceAssembliesCount != 0 || dataSourceFilesCount != 0">
                            <!-- Annotation Section -->
                            <div class="col-md-12" *ngIf="dataSourceAnnotationCount != 0">
                                <hr>
                                <h1 class="col-md-12 page-header">Annotation</h1>
<!--                                <button type="button" style="float: right;" class="btn btn-primary" mat-raised-button (click)="exporter.exportTable('csv', {fileName:'annotation-metadata'})">Download metadata</button>-->
<!--                                <button type="button" style="float: right;" class="btn btn-success" mat-raised-button (click)="downloadAnnotation()">Download all Annotations</button>-->

                                <mat-form-field>
                                    <mat-label>Search</mat-label>
                                    <input matInput (keyup)="annotationSearch($event)" #input>
                                </mat-form-field>
                                <table mat-table [dataSource]="dataSourceAnnotation" matSort style="width: 100%;">
                                    <ng-container matColumnDef="accession">
                                        <mat-header-cell *matHeaderCellDef> Accession </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center">
                                            {{element.accession}}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="annotation">
                                        <mat-header-cell *matHeaderCellDef> Annotation </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center">
                            <span>
                            <a class="no-underline" target="_blank" href="{{element.annotation.gtf}}">GTF</a>,
                        </span>
                                            <span>
                            <a class="no-underline" target="_blank" href="{{element.annotation.gff3}}">GFF3</a>
                        </span>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="proteins">
                                        <mat-header-cell *matHeaderCellDef> Proteins </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center">
                                            <a class="no-underline" target="_blank" href="{{element.proteins.fasta}}">FASTA</a>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="transcripts">
                                        <mat-header-cell *matHeaderCellDef> Transcripts </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center">
                                            <a class="no-underline" target="_blank" href="{{element.transcripts.fasta}}">FASTA</a>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="softmasked_genome">
                                        <mat-header-cell *matHeaderCellDef> Softmasked genome </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center">
                                            <a class="no-underline" target="_blank" href="{{element.softmasked_genome.fasta}}">FASTA</a>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="other_data">
                                        <mat-header-cell *matHeaderCellDef> Other data </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center">
                                            <a class="no-underline" target="_blank" href="{{element.other_data.ftp_dumps}}">FTP dumps</a>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="view_in_browser">
                                        <mat-header-cell *matHeaderCellDef> View in browser </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center">
                                            <a class="no-underline" target="_blank" href="{{element.view_in_browser}}">ensembl.org</a>
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="displayedColumnsAnnotation"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumnsAnnotation;"></mat-row>
                                </table>
                                <mat-paginator #annotationTable [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons [length]="dataSourceAnnotationCount" style="width: 100%;"></mat-paginator>
                            </div>
                           <!-- Assemblies Section -->
                            <div class="col-md-12" *ngIf="dataSourceAssembliesCount != 0">
                                <hr>
                                <h1 class="col-md-12 page-header">Related assemblies</h1>
<!--                                <button type="button" style="float: right;" class="btn btn-primary" mat-raised-button (click)="exporter.exportTable('csv', {fileName:'assemblies-metadata'})">Download metadata</button>-->
<!--                                <button type="button" style="float: right;" class="btn btn-success" mat-raised-button (click)="downloadAssemblies()">Download all Assemblies</button>-->

                                <mat-form-field>
                                    <mat-label>Search</mat-label>
                                    <input matInput (keyup)="assembliesSearch($event)" placeholder="Ex. ILLUMINA" #input>
                                </mat-form-field>
                                <table mat-table [dataSource]="dataSourceAssemblies" matSort style="width: 100%;">
                                    <ng-container matColumnDef="accession">
                                        <mat-header-cell *matHeaderCellDef> Accession </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center">
                                            <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{element.accession}}">{{element.accession}}</a>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="assembly_name">
                                        <mat-header-cell *matHeaderCellDef> Assembly Name </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center"> {{element.assembly_name}} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="description">
                                        <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center"> {{element.description}} </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="version">
                                        <mat-header-cell *matHeaderCellDef> Version </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center"> {{element.version}} </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="displayedColumnsAssemblies"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumnsAssemblies;"></mat-row>
                                </table>
                                <mat-paginator #assembliesTable [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons [length]="dataSourceAssembliesCount" style="width: 100%;"></mat-paginator>
                            </div>
                            <!-- Experiment Files Section -->
                            <!-- <div class="col-md-12" *ngIf="dataSourceFilesCount != 0">
                        </div> -->
                            <div class="col-md-12" *ngIf="dataSourceFilesCount != 0">
                                <hr>
                                <h1 class="col-md-12 page-header">Related files</h1>
                                <mat-expansion-panel (opened)="expanded()">
                                    <mat-expansion-panel-header class="page-header">
                                        Column Selection
                                    </mat-expansion-panel-header>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <mat-checkbox *ngFor="let data of experimentColumnsDefination" class="col-md-4" color="primary" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()" [checked]="data.selected" (change)="showSelectedColumn(data, $event.checked)">
                                                {{data.column}}
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                                <br />
<!--                                <button type="button" style="float: right;" class="btn btn-primary" mat-raised-button (click)="exporter.exportTable('csv', {fileName:'experiments-metadata'})">Download metadata</button>-->
<!--                                <button type="button" style="float: right;" class="btn btn-success" mat-raised-button (click)="downloadRawFiles()">Download all FASTQ files</button>-->

                                <mat-form-field>
                                    <mat-label>Search</mat-label>
                                    <input matInput (keyup)="filesSearch($event)" placeholder="Ex. ILLUMINA" #input>
                                </mat-form-field>
                                <div class="table-container">
                                    <table mat-table [dataSource]="dataSourceFiles">
                                        <ng-container matColumnDef="study_accession">
                                            <mat-header-cell *matHeaderCellDef> Study Accession </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{element.study_accession}}">{{element.study_accession}}</a>
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="sample_accession">
                                            <mat-header-cell *matHeaderCellDef> Sample Accession </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{element.sample_accession}}">{{element.sample_accession}}</a>
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="experiment_accession">
                                            <mat-header-cell *matHeaderCellDef> Experiment Accession </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{element.experiment_accession}}">{{element.experiment_accession}}</a>
                                            </mat-cell>
                                        </ng-container>
                                        <!-- Third Column -->
                                        <ng-container matColumnDef="run_accession">
                                            <mat-header-cell *matHeaderCellDef> Run Accession </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{element.run_accession}}">{{element.run_accession}}</a>
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="tax_id">
                                            <mat-header-cell *matHeaderCellDef> Tax Id </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/Taxon:{{element.tax_id}}">{{element.tax_id}}</a>
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="scientific_name">
                                            <mat-header-cell *matHeaderCellDef> Scientific Name </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.scientific_name}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="fastq_ftp">
                                            <mat-header-cell *matHeaderCellDef> FASTQ FTP </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font">
                                                <mat-list class="list-group ">
                                                    <mat-list-item role="listitem" *ngFor="let fastq of element.fastq_ftp">
                                                        <a href="ftp://{{fastq}}">{{element.run_accession}}</a>
                                                    </mat-list-item>
                                                </mat-list>
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="submitted_ftp">
                                            <mat-header-cell *matHeaderCellDef> Submitted FTP </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font">
                                                <mat-list class="list-group ">
                                                    <mat-list-item role="listitem" *ngFor="let submitted_ftp of element.submitted_ftp">
                                                        <a href="ftp://{{submitted_ftp}}">{{element.study_accession}}</a>
                                                    </mat-list-item>
                                                </mat-list>
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="sra_ftp">
                                            <mat-header-cell *matHeaderCellDef> SRA FTP </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font">
                                                <mat-list class="list-group ">
                                                    <mat-list-item role="listitem" *ngFor="let sra_ftp of element.sra_ftp">
                                                        <a href="ftp://{{sra_ftp}}">{{element.sra_ftp}}</a>
                                                    </mat-list-item>
                                                </mat-list>
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="base_count">
                                            <mat-header-cell *matHeaderCellDef> Base count </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font">
                                                {{element.base_count}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="broker_name">
                                            <mat-header-cell *matHeaderCellDef> Broker name </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.broker_name}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="center_name">
                                            <mat-header-cell *matHeaderCellDef> Center name </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.center_name}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="cram_index_aspera">
                                            <mat-header-cell *matHeaderCellDef> Cram index aspera </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font">
                                                {{element.cram_index_aspera}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="cram_index_galaxy">
                                            <mat-header-cell *matHeaderCellDef> Cram index galaxy </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.cram_index_galaxy}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="cram_index_ftp">
                                            <mat-header-cell *matHeaderCellDef> Cram index FTP </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.cram_index_ftp}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="experiment_alias">
                                            <mat-header-cell *matHeaderCellDef> Experiment alias </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.experiment_alias}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="experiment_title">
                                            <mat-header-cell *matHeaderCellDef> Experiment title </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font">
                                                {{element.experiment_title}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="fastq_aspera">
                                            <mat-header-cell *matHeaderCellDef> Fastq aspera </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.fastq_aspera}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="fastq_bytes">
                                            <mat-header-cell *matHeaderCellDef> Fastq bytes </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.fastq_bytes}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="fastq_galaxy">
                                            <mat-header-cell *matHeaderCellDef> Fastq galaxy </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font">
                                                {{element.fastq_galaxy}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="fastq_md5">
                                            <mat-header-cell *matHeaderCellDef> Fastq md5 </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.fastq_md5}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="first_created">
                                            <mat-header-cell *matHeaderCellDef> First created </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.first_created}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="first_public">
                                            <mat-header-cell *matHeaderCellDef> First public </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font">
                                                {{element.first_public}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="instrument_platform">
                                            <mat-header-cell *matHeaderCellDef> Instrument Platform </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font">
                                                {{element.instrument_platform}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="instrument_model">
                                            <mat-header-cell *matHeaderCellDef> Instrument Model </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.instrument_model}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="last_updated">
                                            <mat-header-cell *matHeaderCellDef> Last updated </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font">
                                                {{element.last_updated}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="library_layout">
                                            <mat-header-cell *matHeaderCellDef> Library Layout </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.library_layout}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="library_name">
                                            <mat-header-cell *matHeaderCellDef> Library name </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.library_name}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="library_strategy">
                                            <mat-header-cell *matHeaderCellDef> Library Strategy </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.library_strategy}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="library_source">
                                            <mat-header-cell *matHeaderCellDef> Library Source </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.library_source}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="library_selection">
                                            <mat-header-cell *matHeaderCellDef> Library Selection </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font">
                                                {{element.library_selection}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="library_construction_protocol">
                                            <mat-header-cell *matHeaderCellDef> Library Construction </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font">
                                                {{element.library_construction_protocol}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="nominal_length">
                                            <mat-header-cell *matHeaderCellDef> Nominal length </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font">
                                                {{element.nominal_length}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="nominal_sdev">
                                            <mat-header-cell *matHeaderCellDef> Nominal sdev </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.nominal_sdev}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="read_count">
                                            <mat-header-cell *matHeaderCellDef> Read count </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.read_count}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="run_alias">
                                            <mat-header-cell *matHeaderCellDef> Run alias </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.run_alias}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="sample_alias">
                                            <mat-header-cell *matHeaderCellDef> Sample alias </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.sample_alias}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="sample_title">
                                            <mat-header-cell *matHeaderCellDef> Sample title </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font">
                                                {{element.sample_title}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="secondary_sample_accession">
                                            <mat-header-cell *matHeaderCellDef> Secondary sample accession </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{element.secondary_sample_accession}}">{{element.secondary_sample_accession}}</a></mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="secondary_study_accession">
                                            <mat-header-cell *matHeaderCellDef> Secondary study accession </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{element.secondary_study_accession}}">{{element.secondary_study_accession}}</a></mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="sra_aspera">
                                            <mat-header-cell *matHeaderCellDef> SRA aspera </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.sra_aspera}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="sra_bytes">
                                            <mat-header-cell *matHeaderCellDef> SRA bytes </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.sra_bytes}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="sra_galaxy">
                                            <mat-header-cell *matHeaderCellDef> SRA galaxy </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.sra_galaxy}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="sra_md5">
                                            <mat-header-cell *matHeaderCellDef> SRA md5 </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.sra_md5}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="study_alias">
                                            <mat-header-cell *matHeaderCellDef> Study alias </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font">
                                                {{element.study_alias}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="study_title">
                                            <mat-header-cell *matHeaderCellDef> Study title </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font">
                                                {{element.study_title}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="submission_accession">
                                            <mat-header-cell *matHeaderCellDef> Submission accession </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font">
                                                {{element.submission_accession}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="submitted_aspera">
                                            <mat-header-cell *matHeaderCellDef> Submitted aspera </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.submitted_aspera}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="submitted_bytes">
                                            <mat-header-cell *matHeaderCellDef> Submitted bytes </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.submitted_bytes}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="submitted_format">
                                            <mat-header-cell *matHeaderCellDef> Submitted format </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.submitted_format}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="submitted_galaxy">
                                            <mat-header-cell *matHeaderCellDef> Submitted galaxy </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.submitted_galaxy}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="submitted_md5">
                                            <mat-header-cell *matHeaderCellDef> Submitted md5 </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="assemblies-font">
                                                {{element.submitted_md5}} </mat-cell>
                                        </ng-container>


                                        <mat-header-row *matHeaderRowDef="displayedColumnsFiles"></mat-header-row>
                                        <mat-row *matRowDef="let row; columns: displayedColumnsFiles;"></mat-row>
                                    </table>
                                </div>
                                <mat-paginator #experimentsTable [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons [length]="dataSourceFilesCount"></mat-paginator>
                            </div>
                        </mat-tab>
                        <mat-tab label="Metadata" *ngIf="bioSampleObj?.records && bioSampleObj?.records.length">
                            <!-- Related Organisms -->
                            <div class="col-md-12" *ngIf="bioSampleObj?.records.length">
                                <hr>
                                <h1 class="col-md-12 page-header">Related Organisms</h1>
                                <mat-form-field>
                                    <mat-label>Search</mat-label>
                                    <input matInput [(ngModel)]='searchText' (keyup)="getSearchResults()" placeholder="Ex. ILLUMINA" #input>
                                </mat-form-field>

                                <div class="table-container">
                                    <table mat-table [dataSource]="dataSourceRecords" matSort >
                                        <ng-container matColumnDef="accession">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header> BioSample ID </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="txt-center">
                                                <a [routerLink]="['/data/organism/details/', element.accession]">
                                                    {{element.accession}}</a>
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="organism">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header> Organism </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="txt-center">
                                                {{element.organism}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="commonName">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>Common Name </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="txt-center"> {{element?.commonName}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="sex">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header> Sex </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="txt-center"> {{element.sex}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="organismPart">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header> Organism part </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="txt-center"> {{element.organismPart}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="trackingSystem">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header> Tracking status
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element">
                                                <mat-chip-set>
                                                    <mat-chip [ngStyle]="{'background-color': 'gold'}">{{element.trackingSystem}}</mat-chip>
                                                </mat-chip-set>
                                            </mat-cell>
                                        </ng-container>

                                        <mat-header-row *matHeaderRowDef="specDisplayedColumns"></mat-header-row>
                                        <mat-row *matRowDef="let row; columns: specDisplayedColumns;"></mat-row>
                                    </table>
                                </div>
                                <mat-paginator style="width: 100%;" [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons [length]="specBioSampleTotalCount"></mat-paginator>
                            </div> </mat-tab>
                        <mat-tab label="Symbionts"  *ngIf="bioSampleObj?.symbionts_records && bioSampleObj?.symbionts_records.length">
                            <div class="col-md-12" *ngIf="bioSampleObj?.symbionts_records.length">
                                <hr>
                                <h1 class="col-md-12 page-header">Related Symbionts</h1>

                                    <div class="table-container">
                                            <table  mat-table [dataSource]="dataSourceSymbiontsRecords" matSort style="width: 100%;overflow: auto;height: 100%;">
                                                <ng-container matColumnDef="accession">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header> BioSample ID
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" class="txt-center">
                                                        <a href="https://www.ebi.ac.uk/biosamples/samples/{{element.accession}}">
                                                            {{element.accession}}</a>
                                                    </mat-cell>
                                                </ng-container>

                                                <ng-container matColumnDef="organism">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Organism </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" class="txt-center">
                                                        {{element.organism}}
                                                    </mat-cell>
                                                </ng-container>

                                                <ng-container matColumnDef="commonName">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Common Name
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" class="txt-center"> {{element?.commonName}}
                                                    </mat-cell>
                                                </ng-container>

                                                <ng-container matColumnDef="sex">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Sex </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" class="txt-center"> {{element.sex}}
                                                    </mat-cell>
                                                </ng-container>

                                                <ng-container matColumnDef="organismPart">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Organism part
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" class="txt-center"> {{element.organismPart}}
                                                    </mat-cell>
                                                </ng-container>

                                                <ng-container matColumnDef="trackingSystem">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Tracking status
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element">
                                                        <mat-chip-set>
                                                            <mat-chip [ngStyle]="{'background-color': 'gold'}">{{element.trackingSystem}}</mat-chip>
                                                        </mat-chip-set>
                                                    </mat-cell>
                                                </ng-container>

                                                <mat-header-row *matHeaderRowDef="specDisplayedColumns"></mat-header-row>
                                                <mat-row *matRowDef="let row; columns: specDisplayedColumns;"></mat-row>
                                            </table>
                                    </div>

                                            <mat-paginator #relatedSymbionts style="width: 100%;" [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons [length]="specSymbiontsTotalCount"></mat-paginator>

                                    </div>

                                                        <div class="col-md-12" *ngIf="dataSourceSymbiontsAssembliesCount != 0">
                                                            <hr>
                                                            <h1 class="col-md-12 page-header">Related assemblies</h1>

                                                                <div class="table-container">
                                                                        <table mat-table  [dataSource]="dataSourceSymbiontsAssemblies" matSort >
                                                                            <ng-container matColumnDef="accession">
                                                                                <mat-header-cell *matHeaderCellDef> Accession </mat-header-cell>
                                                                                <mat-cell *matCellDef="let element" class="txt-center">
                                                                                    <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{element.accession}}">{{element.accession}}</a>
                                                                                </mat-cell>
                                                                            </ng-container>
                                                                            <ng-container matColumnDef="assembly_name">
                                                                                <mat-header-cell *matHeaderCellDef> Assembly Name </mat-header-cell>
                                                                                <mat-cell *matCellDef="let element" class="txt-center"> {{element.assembly_name}}
                                                                                </mat-cell>
                                                                            </ng-container>
                                                                            <ng-container matColumnDef="description">
                                                                                <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
                                                                                <mat-cell *matCellDef="let element" class="txt-center"> {{element.description}} </mat-cell>
                                                                            </ng-container>

                                                                            <ng-container matColumnDef="version">
                                                                                <mat-header-cell *matHeaderCellDef> Version </mat-header-cell>
                                                                                <mat-cell *matCellDef="let element" class="txt-center"> {{element.version}} </mat-cell>
                                                                            </ng-container>

                                                                            <mat-header-row *matHeaderRowDef="displayedColumnsAssemblies"></mat-header-row>
                                                                            <mat-row *matRowDef="let row; columns: displayedColumnsAssemblies;"></mat-row>
                                                                        </table>
                                                                </div>
                                                                        <mat-paginator #assembliesSymbiontsTable [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons [length]="dataSourceSymbiontsAssembliesCount" style="width: 100%;" ></mat-paginator>



                                                        </div>
                            <hr>
                        </mat-tab>
                        <mat-tab label="Metagenomes"  *ngIf="bioSampleObj?.metagenomes_records && bioSampleObj?.metagenomes_records.length">
                            <div class="col-md-12" *ngIf="bioSampleObj?.metagenomes_records.length">
                                <hr>
                                <h1 class="col-md-12 page-header">Related Metagenomes </h1>
                                        <div class="table-container">

                                            <table  mat-table [dataSource]="dataSourceMetagenomesRecords" matSort >
                                                <ng-container matColumnDef="accession">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header> BioSample ID
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" class="txt-center">
                                                        <a href="https://www.ebi.ac.uk/biosamples/samples/{{element.accession}}">
                                                            {{element.accession}}</a>
                                                    </mat-cell>
                                                </ng-container>

                                                <ng-container matColumnDef="organism">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Organism </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" class="txt-center">
                                                        {{element.organism}}
                                                    </mat-cell>
                                                </ng-container>

                                                <ng-container matColumnDef="commonName">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Common Name
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" class="txt-center"> {{element?.commonName}}
                                                    </mat-cell>
                                                </ng-container>

                                                <ng-container matColumnDef="sex">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Sex </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" class="txt-center"> {{element.sex}}
                                                    </mat-cell>
                                                </ng-container>

                                                <ng-container matColumnDef="organismPart">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Organism part
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" class="txt-center"> {{element.organismPart}}
                                                    </mat-cell>
                                                </ng-container>

                                                <ng-container matColumnDef="trackingSystem">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Tracking status
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element">
                                                        <mat-chip-set>
                                                            <mat-chip [ngStyle]="{'background-color': 'gold'}">{{element.trackingSystem}}</mat-chip>
                                                        </mat-chip-set>
                                                    </mat-cell>
                                                </ng-container>

                                                <mat-header-row *matHeaderRowDef="specDisplayedColumns"></mat-header-row>
                                                <mat-row *matRowDef="let row; columns: specDisplayedColumns;"></mat-row>
                                            </table>
                                        </div>
                                            <mat-paginator #relatedMetagenomes style="width: 100%;"
                                                           [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons
                                                           [length]="specMetagenomesTotalCount"></mat-paginator>

                            </div>
                            <div class="col-md-12" *ngIf="dataSourceMetagenomesAssembliesCount != 0">
                                <hr>
                                <h1 class="col-md-12 page-header">Related assemblies</h1>

                                    <div class="table-container">
                                            <table mat-table  [dataSource]="dataSourceMetagenomesAssemblies" matSort>
                                                <ng-container matColumnDef="accession">
                                                    <mat-header-cell *matHeaderCellDef> Accession </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" class="txt-center">
                                                        <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{element.accession}}">{{element.accession}}</a>
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="assembly_name">
                                                    <mat-header-cell *matHeaderCellDef> Assembly Name </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" class="txt-center"> {{element.assembly_name}}
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="description">
                                                    <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" class="txt-center"> {{element.description}} </mat-cell>
                                                </ng-container>

                                                <ng-container matColumnDef="version">
                                                    <mat-header-cell *matHeaderCellDef> Version </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" class="txt-center"> {{element.version}} </mat-cell>
                                                </ng-container>

                                                <mat-header-row *matHeaderRowDef="displayedColumnsAssemblies"></mat-header-row>
                                                <mat-row *matRowDef="let row; columns: displayedColumnsAssemblies;"></mat-row>
                                            </table>
                                    </div>
                                            <mat-paginator #assembliesMetagenomesTable [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons [length]="dataSourceMetagenomesAssembliesCount" style="width: 100%;"></mat-paginator>

                                    </div>


                            <hr>
                        </mat-tab>
                        <mat-tab label="Geo Location" *ngIf="geoLocation">
                            <!-- Geo Section -->
                            <hr>
                            <div class="col-md-12" *ngIf="geoLocation">
                                <app-mapcluster [orgGeoList]="orgGeoList" [specGeoList]="specGeoList"></app-mapcluster>
                            </div>
                            <hr>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>


