<div id="overlay"></div>
<div id="dynamic">
    <div class="container">
        <div class="row">
            <div class="col-md-11" style="text-align: center">
                <h1>The Aquatic Symbiosis Project</h1>
                <br />
                <h2>
                    An ambitious project to read the genomes of 1,000 freshwater and marine species that represent more than 500 symbiotic relationships to provide vitally needed genetic information for conservationists and biologists to understand how species evolve and
                    live together.
                </h2>
            </div>
            <div class="col-md-1">
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                        <button id="data" type="button" class="btn btn-primary btn-lg btn-block" [routerLink]="['/data']">
                            <span class="material-icons">table_chart</span>
                            Data Portal
                        </button>
                    </div>
                    <div class="col-md-4">
                        <button id="status" type="button" class="btn btn-primary btn-lg btn-block" [routerLink]="['/tracking']">
                            <span class="material-icons">linear_scale</span>
                            Status Tracking
                        </button>
                    </div>
                    <div class="col-md-2"></div>
                </div>
                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-4">
                            <button type="button" id="tree" class="btn btn-primary btn-lg btn-block" [routerLink]="['/tree']">
                                <span class="material-icons">timeline</span>
                                Phylogeny
                            </button>
                        </div>
                        <div class="col-md-4">
                            <button  id="samplingMap" type="button" class="btn btn-primary btn-lg btn-block"  [routerLink]="['/gis']">
                                <span class="material-icons">map</span>
                                Sampling Map
                            </button>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                        <button  id="publications" type="button" class="btn btn-primary btn-lg btn-block" [routerLink]="['/publications']">
                            <span class="material-icons">book</span>
                            Publications
                        </button>
                    </div>
                    <div class="col-md-3"></div>
            </div>
                    <!-- <div class="col-md-4 text-center">
                        <h1 class="page-header">
                            Tweets
                        </h1>
                        <div class="text-center">
                            <a class="twitter-timeline" href="https://twitter.com/SangerToL?ref_src=twsrc%5Etfw" data-height="300" data-theme="light" data-width="650" data-chrome="noborders nofooter noscrollbar transparent">
                            </a>
                            <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                        </div>
                    </div> -->
            </div>
        </div>
    </div>
</div>
