<nav class="navbar navbar-light bg-light navbar-expand-lg">
    <a href="/" class="navbar-brand page-header">
        <img src="../../../assets/asg-logo.png" alt="Logo" style="width:5%;"> Aquatic Symbiosis Project Data Portal
    </a>
    <button class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
    <span class="navbar-toggler-icon"></span>
  </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav ml-auto">
            <li class="navbar-item">
                <a [routerLink]="['/data']" class="nav-link">Data portal</a>
            </li>
            <!-- <li class="navbar-item">
        <a [routerLink]="['/data/specimens']" class="nav-link">Specimens</a>
      </li> -->
            <li class="navbar-item">
                <a [routerLink]="['/tracking']" class="nav-link">Status tracking</a>
            </li>
            <!-- <li class="navbar-item">
                <a [routerLink]="['about']" class="nav-link">About</a>
            </li> -->
            <li class="navbar-item">
                <a [routerLink]="['/tree']" class="nav-link">Phylogeny</a>
            </li>
            <li class="navbar-item">
                <a [routerLink]="['gis']" class="nav-link">Sampling Map</a>
            </li>
            <li class="navbar-item">
                <a [routerLink]="['help']" class="nav-link">Help</a>
            </li>
            <li class="navbar-item">
                <a [routerLink]="['documentation']" class="nav-link">API Documentation</a>
            </li>
        </ul>
    </div>
</nav>
