<div id="overlay"></div>
<div id="dynamic">
    <ngx-spinner bdColor="rgba(0,0,0,0.9)" [fullScreen]="true" size="large" color="#fff" type="ball-clip-rotate-pulse">
    </ngx-spinner>

    <div class="container" style="padding-top: 20px;">
        <div class="row">
            <div class="col-md-2">
                <div class="row">
                    <div class="col-md-12 col-sm-6">
                        <app-filter title="Data Status" isShowCount=true></app-filter>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 col-sm-6">
                        <app-filter title="Symbionts" isShowCount=true filterSize="5"></app-filter>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 col-sm-6">
                        <app-filter title="Metagenomes" isShowCount=true filterSize="5"></app-filter>
                    </div>
                </div>

                <app-phylogeny-filter isShowCount=true></app-phylogeny-filter>
                <app-filter title="Experiment Type" isShowCount="true" filterSize="5"></app-filter>
            </div>
            <div class="col-md-10">
                <h1 class="page-header">Organisms</h1>

                <mat-expansion-panel (opened)="expanded()">
                    <mat-expansion-panel-header class="page-header">
                        Column Selection
                    </mat-expansion-panel-header>
                    <div class="col-md-12">
                        <div class="row">
                            <mat-checkbox *ngFor="let data of dataColumnsDefination" class="col-md-4" color="primary"
                                          (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()"
                                          [checked]="data.selected" (change)="showSelectedColumn(data, $event.checked)">
                                {{data.name}}
                            </mat-checkbox>
                        </div>
                    </div>
                </mat-expansion-panel>
                <br />


<!--                <button type="button" style="float: right;" class="btn btn-primary" mat-raised-button (click)="exporter.exportTable('csv', {fileName:'organisms-metadata'})">Download metadata</button>-->
<!--                <button *ngIf="filterService.activeFilters.length > 0 || filterService.selectedFilterValue != '' && (this.filterService.AnnotationFilters.length > 0 || this.filterService.AnnotationCompleteFilters.length > 0 || this.filterService.AssembliesFilters.length > 0 || this.filterService.RawDataFilters.length > 0)" type="button" style="float: right;"-->
<!--                        class="btn btn-primary" mat-raised-button (click)="openDialog()">Download</button>-->

                <div *ngIf="hasActiveFilters()">
                    <app-active-filter true></app-active-filter>

                    <span *ngIf="(filterService.selectedFilterValue!= undefined && filterService.selectedFilterValue != '')">
                    <button type="button" class="btn btn-success">{{filterService.selectedFilterValue.taxonomy}}
                        <span class="badge badge-light"
                              (click)="filterService.removeTaxaFilter(filterService.selectedFilterValue.taxonomy)">
                            X</span>
                    </button>
                </span>
                    <button class="btn btn-primary" type = "button" (click)="removeFilter()">Remove all filters</button>

                </div>

                <mat-form-field class="searchField">
                    <mat-label>Search</mat-label>
                    <input  #searchInput matInput [(ngModel)]='filterService.searchText'
                            (ngModelChange)="this.searchUpdate.next($event)"
                            placeholder="Ex. lutra" #input>
                </mat-form-field>
                <table mat-table  #table [dataSource]="dataSource" matSort (matSortChange)="customSort($event)">
                    <ng-container matColumnDef="organism">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Organism </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a [routerLink]="['/data/root/details/', element.id]">
                                {{element.organism}} </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="commonName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Common Name </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element?.commonName}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="currentStatus">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Current status </mat-header-cell>
                        <mat-cell *matCellDef="let element">
<!--                            <span [ngClass]="getStatusClass(element.currentStatus)">{{element.currentStatus}}</span>-->
                            <mat-chip-set>
                                <mat-chip [ngStyle]="{'background-color': 'gold'}">{{element.currentStatus}}</mat-chip>
                            </mat-chip-set>

                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="goatInfo">
                        <mat-header-cell *matHeaderCellDef style=" padding-right: 0px; ">External references
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="text-center" style=" padding-right: 0px; ">
                            <span>
                                    <mat-chip [ngStyle]="{'background-color': 'deepskyblue'}">
                                        <a class="mat-standard-chip goat_info" target="_blank" href="{{element?.goatInfo}}"
                                           style="cursor: pointer">
                                            GoaT info
                                        </a>
                                    </mat-chip>
                                <ng-container *ngIf="checkTolidExists(element)">
                                        <mat-chip [ngStyle]="{'background-color': 'palegreen'}" class="tol_qc">
                                            <a class="mat-standard-chip" target="_blank"
                                               href="{{generateTolidLink(element)}}">ToL QC</a>
                                        </mat-chip>
                                </ng-container>
                            </span>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="biosamples">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Metadata submitted to BioSamples
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <span [ngClass]="getStatusClass(element.biosamples)">{{element.biosamples}}</span>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="raw_data">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Raw data submitted to ENA </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <span [ngClass]="getStatusClass(element.raw_data)">{{element.raw_data}}</span>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="mapped_reads">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Mapped reads submitted to ENA
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <span [ngClass]="getStatusClass(element.mapped_reads)">{{element.mapped_reads}}</span>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="assemblies">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Assemblies submitted to ENA
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <span [ngClass]="getStatusClass(element.assemblies_status)">{{element.assemblies_status}}
                            </span>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="annotation_complete">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Annotation complete </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <span [ngClass]="getStatusClass(element.annotation_complete)">
                                {{element.annotation_complete}}</span>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="annotation">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Annotation submitted to ENA
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <span [ngClass]="getStatusClass(element.annotation_status)">{{element.annotation_status}}
                            </span>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </table>
                <mat-paginator [pageSizeOptions]="[15, 30, 50, 100]" showFirstLastButtons [length]="bioSampleTotalCount"
                               (page)="pageChanged($event)" class="paginatorClass"></mat-paginator>
            </div>
        </div>
    </div>


</div>

