<div id="dynamic">
    <div class="container" style="padding-top: 65px;">
        <h1 class="text-center page-header">{{bioSampleId}}</h1>
        <dl class="row">
            <dt class="col-sm-3" *ngIf="bioSampleObj?.commonName">Name:</dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.commonName">{{bioSampleObj?.commonName}}</dd>

            <dt class="col-sm-3">BioSample ID:</dt>
            <dd class="col-sm-9"><a href="http://www.ebi.ac.uk/biosamples/samples/{{bioSampleObj?.accession}}" target="_blank">{{bioSampleObj?.accession}}</a></dd>

            <dt class="col-sm-3">Organism:</dt>
            <dd class="col-sm-9">{{bioSampleObj?.organism}}</dd>

            <dt class="col-sm-3">Organism Part:</dt>
            <dd class="col-sm-9">{{bioSampleObj?.organismPart}}</dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.sex">Sex:</dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.sex">{{bioSampleObj?.sex}}</dd>

            <dt class="col-sm-3">Tax ID:</dt>
            <dd class="col-sm-9">{{bioSampleObj?.taxonId}}</dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.specimenId">Specimen ID:</dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.specimenId">{{bioSampleObj?.specimenId}}</dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.cultureOrStrainId">Culture ID:</dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.cultureOrStrainId">{{bioSampleObj?.cultureOrStrainId}}</dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.lifestage">Lifestage:</dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.lifestage">{{bioSampleObj?.lifestage}}</dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.relationship">Relationship:</dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.relationship">{{bioSampleObj?.relationship}}</dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.gal">Gal:</dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.gal">{{bioSampleObj?.gal}}</dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.galSampleId">Gal Sample ID:</dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.galSampleId">{{bioSampleObj?.galSampleId}}</dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.relationship">Relationship:</dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.relationship">{{bioSampleObj?.relationship}}</dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.collectedBy">Collected By:</dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.collectedBy">{{bioSampleObj?.collectedBy}}</dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.collectingInstitution">Collecting Institution:</dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.collectingInstitution">{{bioSampleObj?.collectingInstitution}}
            </dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.collectionDate">Collection Date:</dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.collectionDate">{{bioSampleObj?.collectionDate}}</dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.geographicLocationCountryAndOrSea">Geographic Location (Country
                and/or
                Sea):</dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.geographicLocationCountryAndOrSea">
                {{bioSampleObj?.geographicLocationCountryAndOrSea}}</dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.geographicLocationRegionAndLocality">Geographic Location (Region
                and
                Locality):</dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.geographicLocationRegionAndLocality">
                {{bioSampleObj?.geographicLocationRegionAndLocality}}</dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.geographicLocationLatitude.text">Geographic Location (Latitude):
            </dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.geographicLocationLatitude.text">
                {{bioSampleObj?.geographicLocationLatitude.text}}</dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.geographicLocationLongitude.text">Geographic Location (Longitude):
            </dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.geographicLocationLongitude.text">
                {{bioSampleObj?.geographicLocationLongitude.text}}</dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.geographicLocationDepth.text">Geographic Location (Depth):</dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.geographicLocationDepth.text">
                {{bioSampleObj?.geographicLocationDepth.text}}</dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.geographicLocationElevation.text">Geographic Location Elevation:
            </dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.geographicLocationElevation.text">
                {{bioSampleObj?.geographicLocationElevation.text}}</dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.habitat">Habitat:</dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.habitat">{{bioSampleObj?.habitat}}</dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.identifiedBy">Identified By:</dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.identifiedBy">{{bioSampleObj?.identifiedBy}}</dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.identifierAffiliation">Identifier Affiliation:</dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.identifierAffiliation">{{bioSampleObj?.identifierAffiliation}}
            </dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.specimenVoucher">Specimen Voucher:</dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.specimenVoucher">{{bioSampleObj?.specimenVoucher}}</dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.projectName">Project Name:</dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.projectName">{{bioSampleObj?.projectName}}</dd>

            <dt class="col-sm-3" *ngIf="bioSampleObj?.sampleDerivedFrom">Sample Derived From:</dt>
            <dd class="col-sm-9" *ngIf="bioSampleObj?.sampleDerivedFrom"><a href="http://www.ebi.ac.uk/biosamples/samples/{{bioSampleObj?.sampleDerivedFrom}}" target="_blank">{{bioSampleObj?.sampleDerivedFrom}}</a></dd>

            <!-- Custom Fields Section -->
            <div class="col-sm-12">
                <hr>
                <div class="row" *ngFor="let sample of bioSampleObj?.customFields">
                    <dt class="col-sm-3">{{sample?.name}}</dt>
                    <dd class="col-sm-9">
                        <a *ngIf="sample.ontologyTerms?.length > 0; else simple" href="{{sample.ontologyTerms[0]}}">{{sample?.value}}</a>
                        <ng-template #simple>
                            {{sample?.value}}
                        </ng-template>
                    </dd>
                </div>
            </div>

            <div class="col-sm-12" *ngIf="bioSampleObj?.specimens?.length">
                <hr>
                <h1 class="col-sm-12 page-header">Related Specimens</h1>
                <button type="button" style="float: right;" class="btn btn-primary" mat-raised-button (click)="exporter.exportTable('csv', {fileName:'related-specimens-metadata'})">Download
                    metadata</button>
                <mat-form-field>
                    <mat-label>Search</mat-label>
                    <input matInput [(ngModel)]='searchText' (keyup)="getSearchResults()" placeholder="Ex. ILLUMINA" #input>
                </mat-form-field>
                <div class="row">
                    <div class="col-sm-2">
                        <div class="row">
                            <div class="col-md-12 col-sm-6">
                                <div class="card bg-lite mb-3 filter-top">
                                    <h3 class="card-header filter-heading">
                                        <span #lbltextSex>Sex</span>
                                        <i class="material-icons float-right sex-inactive" *ngIf="sexFilters.length > 3 && isSexFilterCollapsed" (click)="toggleCollapse(lbltextSex.innerText)">add</i>
                                        <i class="material-icons float-right sex-inactive" *ngIf="sexFilters.length > 3 && !isSexFilterCollapsed" (click)="toggleCollapse(lbltextSex.innerText)">remove</i>
                                    </h3>
                                    <ul id="sex-inactive" class="list-group" [ngClass]="{'long-list': itemLimitSexFilter > filterSize}">
                                        <ng-container *ngFor="let filter of sexFilters; let i = index">
                                            <li id="{{i}}" class="list-group-item d-flex justify-content-between align-items-center sex-inactive" [ngClass]="checkFilterIsActive(filter.key)" (click)="onFilterClick($event, lbltextSex.innerText, filter.key)" style="cursor: pointer; padding: .75rem 0.25rem;"
                                                *ngIf="i<itemLimitSexFilter && filter.key != ''">
                                                {{filter.key}}
                                                <span class="badge badge-pill badge-info">{{filter.doc_count}}</span>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="card bg-lite mb-3 filter-top">
                                    <h3 class="card-header filter-heading">
                                        <span #lbltextOrgPart>Organism Part</span>
                                        <i class="material-icons float-right org-part-inactive" *ngIf="organismPartFilters.length > 3 && isOrganismPartCollapsed" (click)="toggleCollapse(lbltextOrgPart.innerText)">add</i>
                                        <i class="material-icons float-right org-part-inactive" *ngIf="organismPartFilters.length > 3 && !isOrganismPartCollapsed" (click)="toggleCollapse(lbltextOrgPart.innerText)">remove</i>
                                    </h3>
                                    <ul id="org-part-inactive" class="list-group" [ngClass]="{'long-list': itemLimitOrgFilter > filterSize}">
                                        <ng-container *ngFor="let filter of organismPartFilters; let i = index">
                                            <li id="{{i}}" class="list-group-item d-flex justify-content-between align-items-center org-part-inactive" [ngClass]="checkFilterIsActive(filter.key)" (click)="onFilterClick($event, lbltextOrgPart.innerText, filter.key)" style="cursor: pointer; padding: .75rem 0.25rem;"
                                                *ngIf="i<itemLimitOrgFilter && filter.key != ''">
                                                {{filter.key}}
                                                <span class="badge badge-pill badge-info">{{filter.doc_count}}</span>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-10">
                        <div class="row">
                            <h1 *ngIf="activeFilters.length !== 0">Filters:
                                <button type="button" class="btn btn-success" *ngFor="let filter of activeFilters">
                                    {{filter}}
                                    <span class="badge badge-light" (click)="removeFilter(filter)">X</span>
                                </button>
                                <button type="button" class="btn btn-primary" (click)="removeAllFilters()">
                                    Remove all filters
                                </button>
                            </h1>
                            <table mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSourceRecords" matSort style="width: 100%;">
                                <ng-container matColumnDef="accession">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> BioSample ID </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="txt-center">
                                        <a [routerLink]="['/data/specimens/details/', element.accession]">
                                            {{element.accession}}</a>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="organism">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Organism </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="txt-center">
                                        {{element.organism}}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="commonName">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Common Name </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="txt-center"> {{element?.commonName}}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="sex">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Sex </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="txt-center"> {{element.sex}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="organismPart">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Organism part </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="txt-center"> {{element.organismPart}}
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="specDisplayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: specDisplayedColumns;"></mat-row>
                            </table>
                            <mat-paginator style="width: 100%;" [pageSizeOptions]="[20, 30, 50]" showFirstLastButtons [length]="bioSampleTotalCount"></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12" *ngIf="bioSampleObj?.annotation">
                <h1 class="col-sm-12 page-header">Annotations</h1>
                <ul class="list-group">
                    <li class="list-group-item list-group-item-success">
                        <a href="{{bioSampleObj?.annotation}}">{{bioSampleObj?.annotation}}</a>
                    </li>
                </ul>
            </div>
        </dl>
    </div>
</div>
