import { Component, OnInit } from '@angular/core';
import { SwaggerUIBundle } from 'swagger-ui-dist';

@Component({
  standalone: true,
  selector: 'app-api',
  templateUrl: './api.component.html',
  styleUrls: ['./api.component.css']
})
export class ApiComponent   {

  constructor() { }

}
